import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { FiltroModel } from 'src/app/models/filtro.model';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import Swal from 'sweetalert2';
import { IEntendendoEntidadeListaModel } from 'src/app/models/entendendo-entidade-lista.model';
import { EntendendoEntidadeService } from 'src/app/services/entendendo-entidade.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { ModalSelecaoQuestionarioComponent } from '../modal-selecao-questionario/modal-selecao-questionario.component';
import { IModeloQuestionarioItemModel } from 'src/app/models/modelo-questionario.model';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-gerar-questionario',
  templateUrl: './gerar-questionario.component.html',
  styleUrls: ['./gerar-questionario.component.scss']
})
export class GerarQuestionarioComponent extends BaseListComponent {
  public filtro = {} as FiltroModel;
  model: any;
  unidadeGestoraId: number;
  modeloRelatorioId: number;
  
  
  constructor(
    private authService: AuthService,
    private entendendoEntidadeService: EntendendoEntidadeService,
    private matDialog: MatDialog,
    private router: Router,
    private sharedService: SharedService
  ) { 
    super();
  }

  ngOnInit(): void {
    this.unidadeGestoraId = this.sharedService.getId();
  }

  public gerarQuestionario() {
    this.entendendoEntidadeService
    .inserir(this.modeloRelatorioId,this.unidadeGestoraId)
    .then((res) => {
      if (res.sucesso) {
        this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=1`);
        
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem);
    })
    .finally(() => {
      // this.buscar();
    });
  }

  public buscarQuestionario(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '650px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;

    const modal = this.matDialog.open(ModalSelecaoQuestionarioComponent, dialogConfig);
    modal.afterClosed().subscribe((data) => {
      if(data){
        this.filtro.pesquisa = data.nome;
        this.modeloRelatorioId = data.id;
      }
    })
  }

  public exibirModalquestionario(){
    
  }

}
