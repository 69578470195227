import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { ToastrService } from "ngx-toastr";
import { IBaseModel } from "src/app/models/base.model";
import { IRiscosModel } from "src/app/models/riscos.model";
import { IEnumModel } from "src/app/models/enum.model";
import { RiscosService } from "src/app/services/riscos.service";
import { CategoriaRiscoService } from "src/app/services/categoria-risco.service";
import { TipoEnfaseService } from "src/app/services/tipo-enfase.service";
import { SubtipoAuditoriaService } from "src/app/services/subtipo-auditoria.service";
import { ICategoriaRiscoModel } from "src/app/models/categoria-risco.model";
import { ITipoEnfaseModel } from "src/app/models/tipo-enfase.model";
import { ISubtipoAuditoriaModel } from "src/app/models/subtipo-auditoria.model";
import { FiltroModel } from "src/app/models/filtro.model";
import { BaseFormComponent } from "../../shared/components/base-form/base-form.component";
import Swal from "sweetalert2";

@Component({
	selector: "app-riscos-form",
	templateUrl: "./riscos-form.component.html",
	styleUrls: ["./riscos-form.component.scss"],
})
export class RiscosFormComponent extends BaseFormComponent implements OnInit {
	public enumCategoriaRisco = [] as IEnumModel[];
	public model: IRiscosModel = {} as IRiscosModel;

	public categorias: { resultado: ICategoriaRiscoModel[] } = {
		resultado: [],
	};
	public tipoEnfase: { resultado: ITipoEnfaseModel[] } = { resultado: [] };
	public subtipoAuditoria = [] as ISubtipoAuditoriaModel[];
	public searchNome: string;
	public searchAtivo = true;

	public form = new FormGroup({
		id: new FormControl({ value: "", disabled: true }),
		nome: new FormControl("", Validators.required),
		categoriaRiscoId: new FormControl(null, Validators.required),
		tipoEnfaseId: new FormControl(null, Validators.required),
		subtipoAuditoriaId: new FormControl(null, Validators.required),
		descricao: new FormControl("", Validators.required),
	});

	constructor(
		route: ActivatedRoute,
		toastr: ToastrService,
		router: Router,
		localeService: BsLocaleService,
		private riscosService: RiscosService,
		public matDialog: MatDialog,
		public categoriaRiscoService: CategoriaRiscoService,
		public tipoEnfaseService: TipoEnfaseService,
		public subtipoAuditoriaService: SubtipoAuditoriaService
	) {
		super(route, toastr, router, localeService, matDialog);

		if (this.novoRegistro) {
			this.titulo = "Novo Risco";
		}
	}

	async ngOnInit() {
		try {
			if (!this.novoRegistro) {
				const res = await this.riscosService.obterPorId(this.id);
				if (res.sucesso) {
					this.model = res.dados;

					if (this.visualizar) {
						this.titulo = "Visualizar Risco - " + this.model.nome;
					} else {
						this.titulo = "Editar Risco - " + this.model.nome;
					}
				} else {
					this.exibirMensagemAlerta(res.mensagem.descricao);
					this.router.navigate(["/riscos"]);
					return;
				}
			}
			this.form.patchValue(this.model);
		} catch (err) {
			this.exibirMensagemErro(err.mensagem);
			this.router.navigate(["/tipo-enfase"]);
		}
		this.carregaCategoriaRisco();
		this.carregaTipoEnfase();
		this.carregaSubtipoAuditoria();
	}

	public async salvarDados(salvarEFechar: boolean) {
		if (this.form.invalid) {
			this.exibirMensagemAlerta("Formulário inválido");
			return;
		}
		this.submit = true;

		this.atualizarModel(this.form.value);

		try {
			let res: IBaseModel<any> = null;

			if (!this.novoRegistro) {
				res = await this.riscosService.atualizar(this.model);
			} else {
				res = await this.riscosService.inserir(this.model);
			}

			if (res.sucesso) {
				this.exibirMensagemSucesso(res.mensagem.descricao);
				if (salvarEFechar == true) this.router.navigate(["/riscos"]);
				else if (this.novoRegistro) {
					this.router.navigate([`/riscos/${res.dados.id}`]);
					await this.ngOnInit();
				}
			} else {
				const validacoes = this.montarMensagensValidacao(res);
				this.exibirMensagemAlerta(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem);
		}
	}
	public async carregaCategoriaRisco() {
		const filtro = {} as FiltroModel;
		this.categoriaRiscoService
			.obter(filtro)
			.then((res) => {
				if (Array.isArray(res.dados.resultado)) {
					this.categorias = res.dados;
				} else {
					console.error(
						"O resultado não é um array:",
						res.dados.resultado
					);
					this.categorias = { resultado: [] };
				}
			})
			.catch((err) => {
				this.exibirMensagemAlerta(err.mensagem.descricao);
			});
	}

	public async carregaTipoEnfase() {
		const filtro = {} as FiltroModel;
		this.tipoEnfaseService
			.obter(filtro)
			.then((res) => {
				if (Array.isArray(res.dados.resultado)) {
					this.tipoEnfase = res.dados;
				} else {
					console.error(
						"O resultado não é um array:",
						res.dados.resultado
					);
					this.tipoEnfase = { resultado: [] };
				}
			})
			.catch((err) => {
				this.exibirMensagemAlerta(err.mensagem.descricao);
			});
	}
	public async carregaSubtipoAuditoria() {
		this.subtipoAuditoriaService
			.obter(this.searchNome, this.searchAtivo, true)
			.then((res) => {
				this.subtipoAuditoria = res.dados;
			})
			.catch((err) => {
				this.exibirMensagemAlerta(err.mensagem.descricao);
			});
	}

	public async onSubmit() {
		this.salvarDados(false);
	}

	private atualizarModel(values: any) {
		Object.assign(this.model, values);
	}

	public onBack() {
		this.router.navigate(["/riscos"]);
	}

	public cancelar() {
		this.router.navigate(["/riscos"]);
	}
}
