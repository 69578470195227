import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EnumAcaoRecurso } from 'src/app/models/enum/acao-recurso.enum';
import { EnumRecursoPerfil } from 'src/app/models/enum/recurso-perfil.enum';
import { IPermissaoModel } from 'src/app/models/permissao.model';
import { AuthService } from 'src/app/services/auth.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import { QuestionarioUGService } from 'src/app/services/questionarioug.service';
import { FiltroQuestionarioUG } from 'src/app/models/filtro-questionario-ug.model';
import { SharedService } from 'src/app/services/shared.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalQuestionarioNovaVersaoSharedComponent } from '../shared/modal-questionario-nova-versao/modal-questionario-nova-versao.component';
import { ModalSolicitarPreenchimentoSharedComponent } from '../shared/modal-solicitar-preenchimento/modal-solicitar-preenchimento.component';
import { EnumTipoQuestionarioUG } from 'src/app/models/enum/tipo-questionario-ug.enum';
import { IQuestionarioUGListaModel } from 'src/app/models/questionario-ug-lista.model';
import { UnidadeGestoraService } from 'src/app/services/unidade-gestora.service';
import { EQuestionarioUGSituacao, EQuestionarioUGSituacaoDescricao } from 'src/app/models/enum/questionario-ug-situacao.enum';

const sortingDataAccessor = (item, property) => {
  switch (property) {
     case 'nome': return item.nome.trim().toLowerCase();   
     case 'descricao': return item.descricao.trim().toLowerCase();  
     case 'ativo':
      return item.ativo; 
    default:
      return item.nome.trim().toLowerCase();
  }
};

@Component({
  selector: 'app-aba-maturidade-risco',
  templateUrl: './aba-maturidade-risco.component.html',
  styleUrls: ['./aba-maturidade-risco.component.scss']
})
export class AbaMaturidadeRiscoComponent extends BaseListComponent  {

public dataSource = new MatTableDataSource<IQuestionarioUGListaModel>([]);
public selectedModel: IQuestionarioUGListaModel;
public displayedColumns: string[] = ['selecao', 'versao', 'data', 'resultado' , 'situacao','status'];
public searchNome: string;
public searchAtivo = true;
public semDados = true;
public permissoes: IPermissaoModel;
public filtro = {} as FiltroQuestionarioUG;
public totalItens: number;
public pageEvent: any;
private currentDialogRef: MatDialogRef<any>;

public botaoNovoDisable = false;
public botaoEditarDisable = false;
public botaoExcluirDisable = false;
public botaoImprimirDisable = false;
public botaoPreenchimentoDisable = false;
public botaoImportarDisable = false;
public botaoVisualizarDisable = false;

@ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
@ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private authService: AuthService,
    private questionarioUGService: QuestionarioUGService,
    private router: Router,
    private matDialog: MatDialog,
    private unidadeGestoraService: UnidadeGestoraService,
    private sharedService: SharedService
  ) { 
    super();
    
  }

  ngOnInit(): void {
    this.setarPermissoes();
  }

  async ngAfterViewInit() {
    this.configurarFiltro();
    await this.configuraTela();
    this.buscar();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
  }

  private setarPermissoes(): void {
    this.permissoes = new IPermissaoModel();
    this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Consultar);
    this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Editar);
    this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Excluir);
    this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.AtivarInativar);
    this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Inserir);
    this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Visualizar);
    this.permissoes.importar = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Importar);
    this.permissoes.imprimir = this.authService.possuiPermissao(EnumRecursoPerfil.QuestionarioMaturidade, EnumAcaoRecurso.Imprimir);

    if(!this.permissoes.consultar){
      this.exibirMensagemErro("Você não possui permissão para Consultar.");
      this.router.navigate([`/unidades-gestoras/${this.sharedService.getId()}`]);
      return;
    }
 }

 

public novo() {

  if(!this.permissoes.incluir){
    this.exibirMensagemErro("Você não possui permissão para Incluir.");
    return;
  }

  if (!this.semDados)
  {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.id = 'modal-component';
  dialogConfig.width = '650px';
  dialogConfig.hasBackdrop = true;
  dialogConfig.disableClose = true;
  dialogConfig.data = {
    source: 'novo',
    tipoQuestionario: EnumTipoQuestionarioUG.MaturidadeRisco
  };
  this.currentDialogRef = this.matDialog.open(ModalQuestionarioNovaVersaoSharedComponent, dialogConfig);
  this.currentDialogRef.afterClosed().subscribe((data) => {
      this.buscar();
  });
} else {
  this.sharedService.setTipoQuestionario(EnumTipoQuestionarioUG.MaturidadeRisco);
  this.router.navigate(['gerar-questionario-shared']);
}
}

public buscar() {
  this.selectedModel = null;
  this.questionarioUGService
    .obter(this.filtro)
    .then((res) => {
      this.totalItens = res.dados.totalItens;
      this.dataSource = new MatTableDataSource<IQuestionarioUGListaModel>(res.dados.resultado);
      this.totalItens = res.dados.totalItens;
      this.paginator = this.tableOnePaginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = sortingDataAccessor;
      this.dataSource._updateChangeSubscription();
      this.semDados = this.dataSource.filteredData.length === 0;
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem);
    })
}

public reativar(id: number) {
  this.questionarioUGService
    .reativar(id)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso('Categoria de Risco reativado com sucesso.');
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err);
    })
    .finally(() => {
      this.buscar();
    });
}

public inativar(id: number) {
  this.questionarioUGService
    .inativar(id)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso('Categoria de Risco inativado com sucesso.');
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem);
    })
    .finally(() => {
      this.buscar();
    });
}

public editar(id: number) {
  if(!this.permissoes.editar){
    this.exibirMensagemErro("Você não possui permissão para Editar.");
    return;
  }

  this.sharedService.setTipoQuestionario(EnumTipoQuestionarioUG.MaturidadeRisco);
  this.router.navigate([`editar-questionario/${id}`]);
}

public importarArquivo(){
  if(!this.permissoes.importar){
    this.exibirMensagemErro("Você não possui permissão para Importar.");
    return;
  }

  this.sharedService.setTipoQuestionario(EnumTipoQuestionarioUG.MaturidadeRisco);
  if (!this.semDados)
  {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.id = 'modal-component';
  dialogConfig.width = '650px';
  dialogConfig.hasBackdrop = true;
  dialogConfig.disableClose = true;
  dialogConfig.data = {
    source: 'importarArquivo'
  };
  this.currentDialogRef = this.matDialog.open(ModalQuestionarioNovaVersaoSharedComponent, dialogConfig);
  this.currentDialogRef.afterClosed().subscribe((data) => {
      this.buscar();
  });
} else {
  this.router.navigate([`importacao-arquivo-questionario-shared`]);
}
  
}

public selecionar(item: any) {
  this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
}

public excluir(id: number) {
  if(!this.permissoes.consultar){
    this.exibirMensagemErro("Você não possui permissão para Excluir.");
    return;
  }

  this.questionarioUGService
    .excluir(id)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso('Questionário excluído com sucesso.');
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem);
    })
    .finally(() => {
      this.buscar();
    });
}

public visualizar(id: number) {
  if(!this.permissoes.visualizar){
    this.exibirMensagemErro("Você não possui permissão para Visualizar.");
    return;
  }

  this.sharedService.setTipoQuestionario(EnumTipoQuestionarioUG.MaturidadeRisco);
  this.router.navigate([`visualizar-questionario/${id}`]);
}

public imprimir(id: number){
  if(!this.permissoes.imprimir){
    this.exibirMensagemErro("Você não possui permissão para Imprimir.");
    return;
  }

  this.sharedService.setTipoQuestionario(EnumTipoQuestionarioUG.MaturidadeRisco);
  this.router.navigate([`imprimir-questionario/${id}`]);
}

private configurarFiltro() {
  this.filtro.idUg = this.sharedService.getId();
  this.filtro.tipoQuestionario = EnumTipoQuestionarioUG.MaturidadeRisco;
  this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
  this.filtro.itensPorPagina = this.tableOnePaginator.pageSize;
  this.filtro.colunaOrder = this.sort.active;
  this.filtro.ativo = this.searchAtivo;
  this.filtro.pesquisa = this.searchNome;
  this.filtro.direcao = this.sort.direction;
}

sortData(sort) {
  this.filtro.colunaOrder = sort.active;
  this.filtro.direcao = sort.direction;

  this.buscar();
}

pageChanged(e) {
  this.filtro.pagina = e.pageIndex + 1;
  e.pageIndex = this.filtro.pagina;
  this.filtro.itensPorPagina = e.pageSize;
  this.buscar();
}



public solicitar(id: number){
  this.sharedService.setTipoQuestionario(EnumTipoQuestionarioUG.MaturidadeRisco);

  const dialogConfig = new MatDialogConfig();
  dialogConfig.id = 'modal-solicitar-preenchimento';
  dialogConfig.width = '650px';
  dialogConfig.hasBackdrop = true;
  dialogConfig.disableClose = true;
  dialogConfig.data = {
    param1: id
  };

  this.currentDialogRef = this.matDialog.open(ModalSolicitarPreenchimentoSharedComponent, dialogConfig);
  this.currentDialogRef.afterClosed().subscribe((data) => {
      this.buscar();
  });
}

public avocar(id: number){
  this.questionarioUGService
    .Avocar(id)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso('Questionário avocado com sucesso.');
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem);
    })
    .finally(() => {
      this.buscar();
    });
}

public avaliar(id: number){
  const situacao = EQuestionarioUGSituacao.Enviado;
  if(this.selectedModel.situacao == EQuestionarioUGSituacaoDescricao[situacao] && this.selectedModel.ativo == true)
{
  this.router.navigate([`avaliar-questionario/${id}`]);
}
else{
  this.exibirMensagemAlerta('Questionario só pode ser avaliado se estiver no Status de Enviado e Ativo');
}
}

public isButtonDisabled(): boolean {
  // Verifica se existe um modelo selecionado
  if (!this.selectedModel) {
    return true;
  }
  
  // Verifica as condições adicionais
  return !this.selectedModel.ativo || 
         !(this.selectedModel.situacao === 'Em Elaboração' || this.selectedModel.situacao === 'Avocado');
}

async configuraTela(){
  
  this.unidadeGestoraService.obterPorId(this.filtro.idUg)
    .then((res) => {
      if(res.sucesso){
        if(!res.dados.ativo){
          this.botaoNovoDisable = true;
          this.botaoImportarDisable = true;
          this.botaoEditarDisable = true;
          this.botaoExcluirDisable = true;
          this.botaoImprimirDisable = true;
          this.botaoPreenchimentoDisable = true;
          this.botaoVisualizarDisable = true;
        }
      }
    });

}

}
