import { BaseFormComponent } from "src/app/shared/components/base-form/base-form.component";
import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TabelaDominioService } from "src/app/services/tabela-dominio.service";
import { IEnumModel } from "src/app/models/enum.model";
import { PerguntaService } from "src/app/services/pergunta.service";
import { MatDialogConfig } from "@angular/material/dialog";
import { QuestionarioUGService } from "src/app/services/questionarioug.service";
import { IQuestionarioUGItemModel, IQuestionarioUGModel } from "src/app/models/questionario-ug.model";
import { EditarQuestionarioModalPerguntaComponent } from "./modal-pergunta/modal-pergunta.component";
import { EditarQuestionarioModalSecaoComponent } from "./modal-secao/modal-secao.component";

@Component({
  selector: 'app-editar-questionario',
  templateUrl: './editar-questionario.component.html',
  styleUrls: ['./editar-questionario.component.scss']
})
export class EditarQuestionarioComponent extends BaseFormComponent implements OnInit {
  
  public model= {} as IQuestionarioUGModel;
  public tiposDocumentos = [] as IEnumModel[];
  columnsToDisplay = ['ordem', 'nome', 'tipoDescription', 'obrigatorio', 'actions'];
  subColumnsToDisplay = ['ordem', 'nome', 'tipoDescription', 'obrigatorio', 'actions'];
  public idUnidadeGestora : number;

  constructor(
    private questionarioUGService: QuestionarioUGService,
    toastr: ToastrService,
    route: ActivatedRoute,
    router: Router,
    private authService: AuthService,
    localeService: BsLocaleService,
    private perguntaService: PerguntaService,
    private tabelaDominioService: TabelaDominioService,
    public matDialog: MatDialog
  ) { 
    super(route, toastr, router, localeService, matDialog);
  }

  public form = new FormGroup({
    id: new FormControl({value: '', disabled:true}),
    nome: new FormControl('', Validators.required),
    tipoDocumento: new FormControl({value:[] as IEnumModel[]})
  })

  async ngOnInit(){
    await this.tabelaDominioService
        .obterTiposDocumentoTrabalho()
        .then((res) => this.tiposDocumentos = res.dados)
        .catch((err) => this.exibirMensagemErro(err));

        if(!this.novoRegistro){
          this.questionarioUGService
          .obterPorId(this.id)
          .then((res) => {
              if(res.sucesso){
                  this.model = res.dados
                  this.idUnidadeGestora = res.dados.unidadeGestoraId;
                  this.form.controls['nome'].setValue(res.dados.nome);
                  this.form.controls['id'].setValue(res.dados.id);
                  this.form.controls['tipoDocumento'].setValue(res.dados.tipoDocumentoId);
                  this.titulo = "Editar Questionário - #" + this.model.id.toString();
                  this.reordenar();
              }else{
                  this.exibirMensagemErro(res.mensagem.descricao);
              }
          })
          .catch((err) => this.exibirMensagemErro(err));
      }else{
        this.titulo = "Novo questionário";
      }        

  }

  public async salvarDados(salvarEFechar: boolean){
    if (this.form.invalid) {
      this.exibirMensagemErro('Formulário invalido!');
      return;
    }

    this.model.nome = this.form.controls['nome'].value;
    this.model.id = this.id ?? 0;
    this.model.tipoDocumentoId = this.form.controls['tipoDocumento'].value;

    let aba = 0;
    if(this.model.tipoDocumentoId == 9){
      aba = 1;
    }else if(this.model.tipoDocumentoId == 10){
      aba = 2;
    }

 
        this.questionarioUGService
          .atualizar(this.model)
          .then((res) => {
            if(res.sucesso){
              this.exibirMensagemSucesso(res.mensagem.descricao);
              if(salvarEFechar){
                this.router.navigateByUrl(`/unidades-gestoras/${this.idUnidadeGestora}?aba=${aba}`);
              }else{
                this.router.navigate([`editar-questionario/${this.model.id}`]);
              }
            }else{
              this.exibirMensagemErro(res.mensagem.descricao);
            }
          })
          .catch((err) => this.exibirMensagemErro(err));
      
  }

  public drop(event: CdkDragDrop<string[]>, element: any) {
    let contadorGeralIndice = 0;
        let contadorItem = 0;
        let contadorSubItem = 0;
        let indiceAnteriorModel = -1;
        let indiceAtualModel = -1;
        let moveu = false;

        this.model.itens.forEach((item, idx) => {
          if(moveu == true){
            return;
          }

          if(item.nome == element.nome){
            if(contadorGeralIndice == event.previousIndex){
              indiceAnteriorModel = idx;
            }
  
            if(contadorGeralIndice == event.currentIndex){
              indiceAtualModel = idx;
            }
  
            if(indiceAnteriorModel >= 0 && indiceAtualModel >= 0){
              moveItemInArray(this.model.itens, indiceAnteriorModel, indiceAtualModel); 
              moveu = true;       
              return;
            }
  
            contadorGeralIndice += 1;
  
            if(item.subItens){
              item.subItens.forEach((subItem, subIdx) => {
                debugger
                if(moveu == true){
                  return;
                }
                
                if(contadorGeralIndice == event.previousIndex){
                  indiceAnteriorModel = subIdx;
                }
  
                if(contadorGeralIndice == event.currentIndex){
                  indiceAtualModel = subIdx;
                }
  
                if(indiceAnteriorModel >= 0 && indiceAtualModel >= 0){
                  moveItemInArray(item.subItens, indiceAnteriorModel, indiceAtualModel); 
                  moveu = true;       
                  return;
                }
  
  
                contadorGeralIndice += 1;
              });
            }
          }
          
        });

        this.reordenar();
  }

  public reordenar(){
    this.model.itens.forEach((item, idx) => {
      item.ordem = (idx + 1);
      item.ordemExibicao = item.ordem.toString();
      if(item.subItens){
        item.subItens.forEach((subItem, subIdx) => {
          const itemPai = this.model.itens.find(_ => _.id == subItem.idItemPai);
          subItem.ordem = subIdx + 1;
          subItem.ordemExibicao = `${item.ordemExibicao}.${subItem.ordem.toString()}`
        });
      }
    });
  }

  public async exibirModalPergunta(idItemPai: number, idx: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '650px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;

    const modal = this.matDialog.open(EditarQuestionarioModalPerguntaComponent, dialogConfig);
    modal.afterClosed().subscribe((data) => {
      if(data){
        let novoItem = {} as IQuestionarioUGItemModel;
        novoItem.idQuestionarioUG = this.id;
        novoItem.nome = data.nome;
        novoItem.tipoId = 2;
        novoItem.tipoDescription = data.tipoEntrada.descricao;
        novoItem.obrigatorio = data.obrigatorio;
        novoItem.perguntaId = data.id;

        if(idItemPai == 0 && idx == 0){
          if(!this.model.itens)
            this.model.itens = [] as IQuestionarioUGItemModel[];

          this.model.itens.push(novoItem);
        }else{
          const itemPai = this.model.itens[idx];
          
          if(!itemPai.subItens)
            itemPai.subItens = [] as IQuestionarioUGItemModel[];

          itemPai.subItens.push(novoItem);
        }
        this.reordenar();
      }
    })
  }

  public exibirModalSecao(element: any){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '650px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      id: element ? element.id : 0,
      idModeloQuestionario : this.id,
      nome: element ? element.nome : ""
    };

    const modal = this.matDialog.open(EditarQuestionarioModalSecaoComponent, dialogConfig);
    modal.afterClosed().subscribe((data) => {
      if(data){
        let novaSecao = data as IQuestionarioUGItemModel;
        

        if(!this.model.itens || this.model.itens.length == 0){
          this.model.itens = [] as IQuestionarioUGItemModel[];
          this.model.itens.push(novaSecao);
          this.reordenar();
          return;
        }

        if(this.model.itens.find((element)=> element.nome.toUpperCase() == novaSecao.nome.toUpperCase())){
          this.exibirMensagemErro("Já existe seção com este nome.");
          return;
        }          

        var isNew = true;
        
        this.model.itens.forEach((element, idx) => {
          if(novaSecao.nomeAnterior == element.nome 
                && novaSecao.nomeAnterior != ""){
            element.nome = novaSecao.nome;
            isNew = false;
          }
        });

        if(isNew){
          this.model.itens.push(novaSecao);
        }
      }
    })
  }

  public excluirItem(elementPai: IQuestionarioUGItemModel, element: IQuestionarioUGItemModel){
    
    if(element.id != 0 && element.obrigatorio == true){
      this.exibirMensagemErro("Não é possível excluir um item do modelo obrigatório.")
      return;
    }

    if(!element.idItemPai || element.idItemPai == 0){
      var idxOf = this.model.itens.indexOf(element, 0);
      this.model.itens.splice(idxOf, 1);
    }else{
      const idxIndicePai = this.model.itens.indexOf(elementPai, 0);
      const itemPai = this.model.itens[idxIndicePai];
      var idxOf = itemPai.subItens.indexOf(element,0);
      itemPai.subItens.splice(idxOf, 1);
    }
    
    this.reordenar();   
    
  }

  public onBack() {
    this.submit = false;

    let tipoDocumento = this.form.controls['tipoDocumento'].value;

    let aba = 0;
    if(tipoDocumento == 9){
      aba = 1;
    }else if(tipoDocumento == 10){
      aba = 2;
    }

    this.router.navigateByUrl(`/unidades-gestoras/${this.model.unidadeGestoraId}?aba=1`);
  }

  public semDados(): boolean{
    return !this.model || !this.model.itens || this.model.itens.length == 0;
  }

}
