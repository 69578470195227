<div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-outline card-lightblue">
            <div class="card-header">
              <h3 class="card-title mb-0">
                <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
                <i *ngIf="!this.id" class="far fa-file pr-2"></i>
                {{ titulo }}
              </h3>
            </div>
            <form
              [formGroup]="form"
              #f="ngForm"
              (keydown.enter)="$event.preventDefault()"
              autocomplete="off">
              <div class="card-body">
  
                <div class="card card-outline card-outline">
                  <div class="card-header">
                    <h3 class="card-title mb-0">
                      Códigos do SIGEFES
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-3">
                        <label for="codigoUg" class="mb-1">Código UG</label>
                        <input
                          type="number"
                          min="0"
                          maxlength="10"
                          onKeyDown="if(this.value >= 999999999){this.value=this.value.slice(0,9)}"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          id="codigoUg"
                          class="form-control form-control-sm"
                          [attr.disabled]="visualizar ? true : null"
                          formControlName="codigoUg"
                          [ngClass]="{
                          'is-invalid':
                          f.submitted && form.controls['codigoUg'].invalid
                          }"
                          appKeyboardlock
                          autocomplete="new-password"
                          />
                        <div
                          *ngIf="f.submitted && form.controls['codigoUg'].invalid"
                          class="invalid-feedback">
                          <div *ngIf="form.controls['codigoUg'].errors.required">
                            Campo obrigatório
                          </div>
                        </div>
                      </div>
  
                      <div class="form-group col-md-3">
                        <label for="gestao" class="mb-1">Gestão</label>
                        <input
                          type="number"
                          min="0"
                          maxlength="10"
                          onKeyDown="if(this.value >= 999999999){this.value=this.value.slice(0,9)}"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          id="gestao"
                          class="form-control form-control-sm"
                          [attr.disabled]="visualizar ? true : null"
                          formControlName="gestao"
                          [ngClass]="{
                          'is-invalid':
                          f.submitted && form.controls['gestao'].invalid
                          }"
                          appKeyboardlock
                          autocomplete="new-password"
                          />
                        <div
                          *ngIf="f.submitted && form.controls['gestao'].invalid"
                          class="invalid-feedback">
                          <div *ngIf="form.controls['gestao'].errors.required">
                            Campo obrigatório
                          </div>
                        </div>
                      </div>
  
                      <div class="form-group col-md-3">
                        <label for="orgaoSuperior" class="mb-1">Órgao Superior</label>
                        <input
                          type="number"
                          min="0"
                          maxlength="10"
                          onKeyDown="if(this.value >= 999999999){this.value=this.value.slice(0,9)}"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          id="orgaoSuperior"
                          [attr.disabled]="visualizar ? true : null"
                          class="form-control form-control-sm"
                          formControlName="orgaoSuperior"
                          [ngClass]="{
                          'is-invalid':
                          f.submitted &&
                          form.controls['orgaoSuperior'].invalid
                          }"
                          appKeyboardlock
                          autocomplete="new-password"
                          />
                        <div
                          *ngIf="f.submitted &&
                          form.controls['orgaoSuperior'].invalid"class="invalid-feedback">
                          <div
                            *ngIf="form.controls['orgaoSuperior'].errors.required">
                            Campo obrigatório
                          </div>
                        </div>
                      </div>
  
                      <div class="form-group col-md-3">
                        <label for="codigoUo" class="mb-1">Código da UO</label>
                        <input
                          type="number"
                          min="0"
                          onKeyDown="if(this.value >= 999999999){this.value=this.value.slice(0,9)}"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          maxlength="10"
                          id="codigoUo"
                          class="form-control form-control-sm"
                          [attr.disabled]="visualizar ? true : null"
                          formControlName="codigoUo"
                          [ngClass]="{
                          'is-invalid':
                          f.submitted && form.controls['codigoUo'].invalid
                          }"
                          appKeyboardlock
                          autocomplete="new-password"
                          />
                        <div
                          *ngIf="f.submitted && form.controls['codigoUo'].invalid"
                          class="invalid-feedback">
                          <div *ngIf="form.controls['codigoUo'].errors.required">
                            Campo obrigatório
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="card card-outline card-outline">
                  <div class="card-header">
                    <h3 class="card-title mb-0">
                      Dados Gerais
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-3">
                        <label for="cnpj" class="mb-1">CNPJ</label>
                        <input
                          type="text"
                          id="cnpj"
                          class="form-control form-control-sm"
                          [attr.disabled]="visualizar ? true : null"
                          formControlName="cnpj"
                          data-inputmask="'mask': '99.999.999/9999-99'"
                          maxlength="19"
                          />
                      </div>
  
                      <div class="form-group col-md-3">
                        <label for="sigla" class="mb-1">Sigla</label>
                        <input
                          type="text"
                          id="sigla"
                          class="form-control form-control-sm"
                          [attr.disabled]="visualizar ? true : null"
                          formControlName="sigla"
                          maxlength="50"
                          [ngClass]="{
                          'is-invalid':
                          f.submitted && form.controls['sigla'].invalid
                          }"
                          />
                        <div
                          *ngIf="f.submitted && form.controls['sigla'].invalid"
                          class="invalid-feedback">
                          <div *ngIf="form.controls['sigla'].errors.required">
                            Campo obrigatório
                          </div>
                        </div>
                      </div>
  
                      <div class="form-group col-md-6">
                        <label for="sigla" class="mb-1">Nome</label>
                        <input
                          type="text"
                          id="nome"
                          class="form-control form-control-sm"
                          [attr.disabled]="visualizar ? true : null"
                          formControlName="nome"
                          maxlength="200"
                          [ngClass]="{
                          'is-invalid':
                          f.submitted && form.controls['nome'].invalid
                          }"
                          />
                        <div
                          *ngIf="f.submitted && form.controls['nome'].invalid"
                          class="invalid-feedback">
                          <div *ngIf="form.controls['nome'].errors.required">
                            Campo obrigatório
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label for="inputOrgaoId" class="mb-1">Órgão do
                          Organograma</label>
                        <div
                          class="input-group input-group-sm"
                          style="width: 100%;">
                          <input
                            type="text"
                            id="inputOrgaoId"
                            class="form-control form-control-sm"
                            [attr.disabled]="visualizar ? true : null"
                            readonly
                            [ngModel]="model.nomeOrgao"
                            [ngModelOptions]="{ standalone: true }"
                            [ngClass]="{
                            'is-invalid':
                            f.submitted && form.controls['orgaoId'].invalid
                            }"
                            />
                          <div class="input-group-append">
                            <button
                              type="button"
                              [hidden]="visualizar"
                              class="btn btn-info"
                              (click)="exibirModalOrgao()">
                              <i class="fas fa-search"></i>
                            </button>
                          </div>
                        </div>
                        <div
                          *ngIf="f.submitted && form.controls['orgaoId'].invalid"
                          class="invalid-feedback">
                          <div *ngIf="form.controls['orgaoId'].errors.required">
                            Campo obrigatório
                          </div>
                        </div>
                      </div>
  
                      <div class="form-group col-md-6">
                        <label for="responsavelId" class="mb-1">Responsável pela
                          Unidade Gestora</label>
                        <div
                          class="input-group input-group-sm"
                          style="width: 100%;">
                          <input
                            type="text"
                            id="responsavelId"
                            class="form-control form-control-sm"
                            [attr.disabled]="visualizar ? true : null"
                            readonly
                            [ngModel]="model.nomeResponsavel"
                            [ngModelOptions]="{ standalone: true }"
                            [ngClass]="{
                            'is-invalid':
                            f.submitted &&
                            form.controls['responsavelId'].invalid
                            }"
                            />
                          <div class="input-group-append">
                            <button
                              type="button"
                              [hidden]="visualizar"
                              class="btn btn-info"
                              (click)="exibirModalResponsavelUnidade()">
                              <i class="fas fa-search"></i>
                            </button>
                          </div>
                        </div>
                        <div
                          *ngIf="f.submitted &&
                          form.controls['responsavelId'].invalid"class="invalid-feedback">
                          <div
                            *ngIf="form.controls['responsavelId'].errors.required">
                            Campo obrigatório
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label for="tipoAdministracaoId" class="mb-1">Tipo de
                          Administração</label>
                        <select
                          id="tipoAdministracaoId"
                          formControlName="tipoAdministracaoId"
                          [attr.disabled]="visualizar ? true : null"
                          [ngModel]="model.tipoAdministracaoId"
                          class="form-control form-control-sm"
                          [ngClass]="{
                          'is-invalid':
                          f.submitted &&
                          form.controls['tipoAdministracaoId'].invalid
                          }">
                          <option
                            *ngFor="let item of enumTipoAdministracao"
                            [value]="item.codigo">
                            {{ item.descricao }}</option>
                        </select>
                        <div
                          *ngIf="f.submitted &&
                          form.controls['tipoAdministracaoId'].invalid"class="invalid-feedback">
                          <div
                            *ngIf="form.controls['tipoAdministracaoId'].errors.required">
                            Campo obrigatório
                          </div>
                        </div>
                      </div>
  
                      <div class="form-group col-md-6">
                        <label for="unidadeGestoraResponsavelId" class="mb-1">UG
                          Responsável</label>
                        <div
                          class="input-group input-group-sm"
                          style="width: 100%;">
                          <input
                            type="text"
                            id="unidadeGestoraResponsavelId"
                            class="form-control form-control-sm"
                            [attr.disabled]="visualizar ? true : null"
                            readonly
                            [ngModel]="model.nomeUnidadeGestoraResponsavel"
                            [ngModelOptions]="{ standalone: true }"
                            [ngClass]="{
                            'is-invalid':
                            f.submitted &&
                            form.controls['unidadeGestoraResponsavelId']
                            .invalid
                            }"
                            />
                          <div class="input-group-append">
                            <button
                              type="button"
                              [hidden]="visualizar"
                              class="btn btn-info"
                              (click)="exibirModalUnidadeGestoraResponsavel()">
                              <i class="fas fa-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label for="unidadeGestoraMaturidadeGRId" class="mb-1">Nível maturidade</label>
                        <div
                          class="input-group input-group-sm"
                          style="width: 100%;">
                          <select
                          id="unidadeGestoraMaturidadeGRId"
                          formControlName="unidadeGestoraMaturidadeGRId"
                          [ngModel]="model.unidadeGestoraMaturidadeGRId"
                          class="form-control form-control-sm"
                          >
                          <option
                            *ngFor="let item of maturidades"
                            [value]="item.id">
                            {{ item.nome }}</option>
                        </select>
                        <div class="input-group-append">
                          <button
                            type="button"
                            [hidden]="visualizar"
                            matTooltip="Visualizar histórico"
                            class="btn btn-info"
                            (click)="abrirModalHistoricoMaturidade()">
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                        </div>
                        <div
                          *ngIf="f.submitted &&
                          form.controls['unidadeGestoraMaturidadeGRId'].invalid"class="invalid-feedback">
                          <div
                            *ngIf="form.controls['unidadeGestoraMaturidadeGRId'].errors.required">
                            Campo obrigatório
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="responsavelDelegadoPermanenteId" class="mb-1">Delegação Permanente</label>
                        <div
                          class="input-group input-group-sm"
                          style="width: 100%;">
                          <input
                            type="text"
                            id="responsavelDelegadoPermanenteId"
                            class="form-control form-control-sm"
                            [attr.disabled]="visualizar ? true : null"
                            readonly
                            [ngModel]="model.nomeResponsavelDelegadoPermanente"
                            [ngModelOptions]="{ standalone: true }"
                            [ngClass]="{
                            'is-invalid':
                            f.submitted &&
                            form.controls['responsavelDelegadoPermanenteId'].invalid
                            }"
                            />
                          <div class="input-group-append">
                            <button
                              type="button"
                              [hidden]="visualizar"
                              class="btn btn-info"
                              (click)="exibirModalResponsavelDelegadoPermanenteUnidade()">
                              <i class="fas fa-search"></i>
                            </button>
                          </div>
                        </div>
                        <div
                          *ngIf="f.submitted &&
                          form.controls['responsavelDelegadoPermanenteId'].invalid"class="invalid-feedback">
                          <div
                            *ngIf="form.controls['responsavelDelegadoPermanenteId'].errors.required">
                            Campo obrigatório
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row pt-3">
                      <div class="form-group col-md-4">
                        <mat-checkbox
                          color="primary"
                          name="switchAuditoriaObrigatoria"
                          formControlName="auditoriaObrigatoria"
                          (change)="model.auditoriaObrigatoria = $event.checked ? true : false"
                          >
                          Auditoria Obrigatória
                        </mat-checkbox>
                        <div *ngIf="model.auditoriaObrigatoria">
                          <mat-form-field>
                            <mat-label>Selecione o ano</mat-label>
                            <mat-select 
                              name="selectAnosObrigatorios"
                              formControlName="anosObrigatorios"
                              [ngModel]="model.anosObrigatorios"
                              multiple
                              >
                              <mat-option *ngFor="let item of enumAnosObrigatorios" 
                              [value]="item.codigo">
                                {{item.nome}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="form-group col-md-4">
                        <mat-checkbox
                          color="primary"
                          name="switchExecutaOrcamento"
                          formControlName="executaOrcamento">
                          Executa Orçamento
                        </mat-checkbox>
                      </div>
                      <div class="form-group col-md-4">
                        <mat-checkbox
                          color="primary"
                          name="switchExecutaObra"
                          formControlName="executaObra">
                          Executa Obra
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="row pt-3">
                      <div class="form-group col-md-4">
                        <mat-checkbox
                          color="primary"
                          name="switchExecutaConvenio"
                          formControlName="executaConvenio">
                          Executa Convênio
                        </mat-checkbox>
                      </div>
                      <div class="form-group col-md-4">
                        <mat-checkbox
                          color="primary"
                          name="switchAuditoriaSistemas"
                          formControlName="auditoriaSistemas">
                          Auditoria de Sistemas de TI
                        </mat-checkbox>
                      </div>
                      <div class="form-group col-md-4">
                        <mat-checkbox
                          color="primary"
                          name="switchEmpresaPublica"
                          formControlName="empresaPublica">
                          Empresa Pública
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="btn-group" [hidden]="modoVisualizar">
                      <button class="btn btn-primary btn-sm mr" (click)="salvarDados(false)">
                          <i class="far fa-save"></i> Salvar
                      </button>
                      <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                      <div class="dropdown-menu" role="menu">
                      <a class="dropdown-item" (click)="salvarDados(false)">Salvar</a>
                      <a class="dropdown-item" (click)="salvarDados(true)">Salvar e fechar</a>
                      </div>
                    </div>
    
                    <button
                      type="button"
                      [hidden]="visualizar"
                      class="btn btn-default btn-sm"
                      (click)="onBack()">
                      <i class="fas fa-times"></i> Cancelar
                    </button>
    
                    <button [hidden]="!visualizar" type="button" class="btn
                      btn-default btn-sm" (click)="onBack()">
                      <i class="fas fa-times"></i> Sair
                    </button>
                </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  