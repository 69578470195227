import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IResponsavelUnidadeGestoraModel } from 'src/app/models/responsavel-unidade-gestora.model';
import { QuestionarioUGRespostaService } from 'src/app/services/questionarioug-resposta.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-delegar',
  templateUrl: './modal-delegar.component.html',
  styleUrls: ['./modal-delegar.component.scss']
})
export class ModalDelegarComponent implements OnInit {
  questionarioId: any;

  constructor(
    private dialogRef: MatDialogRef<ModalDelegarComponent>,
    private questionarioUGService: QuestionarioUGRespostaService,
    public router: Router,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.model = data.dados;
    this.questionarioId = data.questionarioId;
  }

  public model: IResponsavelUnidadeGestoraModel;
  public idResponsavel;

  ngOnInit(): void {
  }

  cancelar() {
    this.dialogRef.close(true);
  }

  async delegar() {
    this.questionarioUGService
      .DelegarQuestionario(this.questionarioId, this.idResponsavel)
      .then((res) => {
          this.dialogRef.close(true);
          this.router.navigate([`questionario/pre-planejamento-resposta/${this.model.id}`]);// remover hardcode

  }
)}

}
