import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IArquivoModel } from 'src/app/models/arquivo.model';
import { AvaliacaoRequest, IListaQuestionarioRespostaUGModel, QuestionarioUGItensResposta } from 'src/app/models/questionario-ug.model';
import { AuthService } from 'src/app/services/auth.service';
import { EntendendoEntidadeService } from 'src/app/services/entendendo-entidade.service';
import { PerguntaService } from 'src/app/services/pergunta.service';
import { QuestionarioUGRespostaService } from 'src/app/services/questionarioug-resposta.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ResponsaveisService } from 'src/app/services/responsaveis.service ';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EnumTipoAvaliacaoQuestionarioUG } from 'src/app/models/enum/tipo-avaliacao-questionario-ug.enum';
import { EnumStatusAvaliacaoQuestionarioUG } from 'src/app/models/enum/status-avaliacao-questionario-ug.enum';
import { ModalAvaliarQuestionarioComponent } from '../modal-avaliar-questionario/modal-avaliar-questionario.component';
import { SharedService } from 'src/app/services/shared.service';
import { EnumTipoQuestionarioUG } from 'src/app/models/enum/tipo-questionario-ug.enum';


@Component({
  selector: 'app-avaliar-questionario',
  templateUrl: './avaliar-questionario.component.html',
  styleUrls: ['./avaliar-questionario.component.scss']
})
export class AvaliarQuestionarioComponent extends BaseFormComponent implements OnInit {
  public model= {} as IListaQuestionarioRespostaUGModel;
  formGroup: FormGroup;
  arquivo: File | null = null;
  displayedColumns: string[] = ['questionAndResponse'];
  public dataSource = new MatTableDataSource<any>([]);
  imprimir: boolean;
  selectedItems: QuestionarioUGItensResposta[] = [];
  selectedIds: number[] = [];
  exibeCamposStatusEmReplica: boolean;
  avaliacao: AvaliacaoRequest;
  tipoQuestionario: number;

  constructor(
    private fb: FormBuilder,
    private questionarioUGRespostaService: QuestionarioUGRespostaService,
    toastr: ToastrService,
    route: ActivatedRoute,
    router: Router,
    private authService: AuthService,
    localeService: BsLocaleService,
    private perguntaService: PerguntaService,
    private tabelaDominioService: TabelaDominioService,
    private responsaveisService: ResponsaveisService,
    public matDialog: MatDialog,
    private sharedService: SharedService
  ) { 
    super(route, toastr, router, localeService, matDialog);
      this.formGroup = this.fb.group({
        respostas: this.fb.array([]),
        arquivos: this.fb.array([]) // Novo FormArray para os arquivos
  });
}

  ngOnInit(): void {
    this.buscar();
    this.tipoQuestionario = this.sharedService.getTipoQuestionario();
  };


  buscar() {
    this.questionarioUGRespostaService.ObterRespostaQuestionario(this.id)
      .then((res) => {
        if (res.sucesso) {
          this.model = res.dados;
          let aba = 0;
          if(this.tipoQuestionario == EnumTipoQuestionarioUG.EntendendoEntidade){
            aba = 1;
          }else if (this.tipoQuestionario == EnumTipoQuestionarioUG.MaturidadeRisco){
            aba = 2;
          }

          this.dataSource = new MatTableDataSource<QuestionarioUGItensResposta>(res.dados.respostas);
  
          // Limpa o FormArray antes de adicionar novos controles
          const respostasFormArray = this.formGroup.get('respostas') as FormArray;
          respostasFormArray.clear();
          const checkboxesFormArray = this.fb.array([]); // Novo FormArray para os checkboxes

          // Adiciona controles ao FormArray dinamicamente
          res.dados.respostas.forEach(item => {
            respostasFormArray.push(new FormControl(item.resposta));
            checkboxesFormArray.push(new FormControl(item.selected || false));
          });
        } else {
          this.exibirMensagemErro(res.mensagem.descricao);
        }
      })
      .catch((err) => this.exibirMensagemErro(err));
  }

  getRespostaFormControl(index: number): FormControl {
    const respostasFormArray = this.formGroup.get('respostas') as FormArray;
    return respostasFormArray.at(index) as FormControl;
  }

  onCheckboxChange(event: MatCheckboxChange, element: any) {
    if (event.checked) {
      this.selectedIds.push(element.idItemQuestionario);
    } else {
      const index = this.selectedIds.indexOf(element.idItemQuestionario);
      if (index > -1) {
        this.selectedIds.splice(index, 1);
      }
    }
  }

  onAvaliar(){
    const idsSelecionados = this.selectedIds;
    const idsSelecionadosString: string = idsSelecionados.join(',');
    const perguntaSelecionada = this.model.respostas.find(item => idsSelecionados.includes(item.idItemQuestionario));   
    // Verifica se há mais de uma pergunta selecionada
    if (idsSelecionados.length > 1) {
      this.exibirMensagemAlerta('Só pode ter um registro selecionado para contestar');
      return;
    }

    // Verifica se há pelo menos uma pergunta selecionada
    if (idsSelecionados.length === 0) {
      this.exibirMensagemAlerta('Selecione uma pergunta para contestar');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '650px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      ids: idsSelecionadosString,
      pergunta: perguntaSelecionada.pergunta,
      resposta: perguntaSelecionada.resposta
    };
 
    const modal = this.matDialog.open(ModalAvaliarQuestionarioComponent, dialogConfig);
    modal.afterClosed().subscribe((data) => {
      if(data.success){
        this.buscar();
       }
    })

  }
  onConcordar(){
    const idsSelecionados = this.selectedIds;
    const idsSelecionadosString: string = idsSelecionados.join(',');
    this.avaliacao = {
      statusAvaliacao: EnumStatusAvaliacaoQuestionarioUG.concordo,
      texto: "",
      tipoAvaliacao: EnumTipoAvaliacaoQuestionarioUG.avaliacao
    };

    this.questionarioUGRespostaService.fazerAvaliacaoOuReplica(idsSelecionadosString, this.avaliacao)
    .then((res) => {
      if (res.sucesso) {
        this.exibirMensagemSucesso('Registro Atualizado com sucesso');
        this.buscar();
      }
      else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })

  }
  onReavaliar(){

  }
  onEncerrarAvaliacao(){
    this.questionarioUGRespostaService.FinalizarQuestionarioAvaliacao(this.id)
    .then((res) => {
      if (res.sucesso) {
        let aba = 0;
        if(this.tipoQuestionario == EnumTipoQuestionarioUG.EntendendoEntidade){
          aba = 1;
        }else if (this.tipoQuestionario == EnumTipoQuestionarioUG.MaturidadeRisco){
          aba = 2;
        }
        this.router.navigateByUrl(`/unidades-gestoras/${this.model.idUG}?aba=${this.aba}`);
      }
      else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })

  }
  onEnviarReplica(){
    this.questionarioUGRespostaService.enviarParaReplica(this.id)
    .then((res) => {
      if (res.sucesso) {
        let aba = 0;
        if(this.tipoQuestionario == EnumTipoQuestionarioUG.EntendendoEntidade){
          aba = 1;
        }else if (this.tipoQuestionario == EnumTipoQuestionarioUG.MaturidadeRisco){
          aba = 2;
        }
        this.router.navigateByUrl(`/unidades-gestoras/${this.model.idUG}?aba=${this.aba}`);
      }
      else{
        this.exibirMensagemErro(res.mensagem.descricao);
      }
    })
  }
  onFechar(){
    let aba = 0;
    if(this.tipoQuestionario == EnumTipoQuestionarioUG.EntendendoEntidade){
      aba = 1;
    }else if (this.tipoQuestionario == EnumTipoQuestionarioUG.MaturidadeRisco){
      aba = 2;
    }
    this.router.navigateByUrl(`/unidades-gestoras/${this.model.idUG}?aba=${this.aba}`);
  }

  isTodosAvaliados() {
    const filteredItems = this.dataSource.data.filter(item => item.idTipoSecaoOuPergunta === 2);
    const todosAvaliados = filteredItems.every(item => item.tipoAvaliacaoAuditor !== '' && item.tipoAvaliacaoAuditor !== null && item.tipoAvaliacaoAuditor !== undefined);

    return todosAvaliados;
  }

}

