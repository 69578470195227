import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import Inputmask from 'inputmask';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IBaseModel } from 'src/app/models/base.model';
import { IEnumModel } from 'src/app/models/enum.model';
import { IResponsavelUnidadeGestoraModel } from 'src/app/models/responsavel-unidade-gestora.model';
import { IUnidadeGestoraModel } from 'src/app/models/unidade-gestora.model';
import { AcessoCidadaoService } from 'src/app/services/acesso-cidadao.service';
import { OrganogramaService } from 'src/app/services/organograma.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { UnidadeGestoraService } from 'src/app/services/unidade-gestora.service';
import Swal from 'sweetalert2';
import { BaseFormComponent } from '../../shared/components/base-form/base-form.component';
import { IUnidadeGestoraMaturidadeGRModel } from 'src/app/models/unidade-gestora-maturidadeGR.model';
import { UnidadeGestoraMaturidadeGRService } from 'src/app/services/unidade-gestora-maturidadeGR.service';
import { FiltroModel } from 'src/app/models/filtro.model';
import { UnidadeGestoraMaturidadeGRHistoricoService } from 'src/app/services/unidade-gestora-maturidadeGRHistorico.service';
import { IFiltroUnidadeGestoraMaturidadeGRHistoricoModel } from 'src/app/models/filtro-unidade-gestora-maturidadegrhistorico.model';
import { HistoricoModalComponent } from 'src/app/shared/components/historico-modal/historico-modal.component';
import { SharedService } from 'src/app/services/shared.service';


@Component({
  selector: 'app-unidade-gestora-form',
  templateUrl: './unidade-gestora-form.component.html',
  styleUrls: ['./unidade-gestora-form.component.scss']
})
export class UnidadeGestoraFormComponent extends BaseFormComponent implements OnInit {
  public model: IUnidadeGestoraModel = {} as IUnidadeGestoraModel;
  public enumTipoAdministracao = [] as IEnumModel[];
  public enumAnosObrigatorios = [] as IEnumModel[];
  public enumFuncoes = [] as IEnumModel[];
  public modelResponsavel: IResponsavelUnidadeGestoraModel = {} as IResponsavelUnidadeGestoraModel;
  public semDados = true;
  public ugDataSource = new MatTableDataSource<IUnidadeGestoraModel>([]);
  public maturidades = [] as IUnidadeGestoraMaturidadeGRModel[];
  public unidadeGestoraId: number;
  public salvarDadosPendente = false;

  public form = new FormGroup({
    codigoUg: new FormControl('', Validators.required),
    gestao: new FormControl('', Validators.required),
    orgaoSuperior: new FormControl('', Validators.required),
    codigoUo: new FormControl('', Validators.required),
    sigla: new FormControl('', Validators.required),
    nome: new FormControl('', Validators.required),
    cnpj: new FormControl(''),
    orgaoId: new FormControl(null, Validators.required),
    responsavelId: new FormControl(null, Validators.required),
    unidadeGestoraResponsavelId: new FormControl(null),
    tipoAdministracaoId: new FormControl('', Validators.required),
    auditoriaObrigatoria: new FormControl({value:'',disabled: this.visualizar}),
    anosObrigatorios: new FormControl({value:'', disabled: this.visualizar }),
    executaOrcamento: new FormControl({value:'',disabled: this.visualizar}),
    executaObra: new FormControl({value:'',disabled: this.visualizar}),
    executaConvenio: new FormControl({value:'',disabled: this.visualizar}),
    auditoriaSistemas: new FormControl({value:'',disabled: this.visualizar}),
    empresaPublica: new FormControl({value:'',disabled: this.visualizar}),
    unidadeGestoraMaturidadeGRId: new FormControl('', Validators.required),
  });

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    private tabelaDominioService: TabelaDominioService,
    private unidadeGestoraService: UnidadeGestoraService,
    private acessoCidadaoService: AcessoCidadaoService,
    private organogramaService: OrganogramaService,
    private unidadeGestoraMaturidadeGRService: UnidadeGestoraMaturidadeGRService,
    private unidadeGestoraMaturidadeGRHistoricoService: UnidadeGestoraMaturidadeGRHistoricoService,
    public matDialog: MatDialog,
    private sharedService: SharedService
  ) {
    super(route, toastr, router, localeService, matDialog);

    if (this.novoRegistro) {
      this.titulo = 'Nova Unidade Gestora';
    }
  }

  ngAfterViewInit(){
    const numeroAba = this.route.snapshot.queryParamMap.get("aba");
    debugger
    if (numeroAba) {
      this.mudarAba(Number(numeroAba));
    }
  }

  async ngOnInit() {
    
    Inputmask().mask(document.querySelectorAll('input'));
    this.adicionarEnums();
    this.carregaMaturidades();
    this.sharedService.setId(this.id);
    try {
      if (!this.novoRegistro) {
        const res = await this.unidadeGestoraService.obterPorId(this.id);
        if (res.sucesso) {
          this.model = res.dados;

          if (this.visualizar) {
            this.titulo = 'Visualisar Unidade Gestora - ' + this.model.sigla;
          }
          else{
            this.titulo = 'Editar Unidade Gestora - ' + this.model.sigla;
          }
          if (this.model.organogramaId) {
            this.form.controls['codigoUg'].enable();
            this.form.controls['cnpj'].enable();
          }

          if (this.model.importadoViaServico) {
            this.desabilitarUgImportadoViaServico();
          }

        } else {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: res.mensagem.descricao,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.router.navigate(['/unidades-gestoras']);
          return;
        }
      }

      if (!this.model.importadoViaServico) {
        const tiposAdministracao: any[] = [];
        let result: any;

        result = await this.tabelaDominioService.obterTipoAdministracao();
        const { dados } = result[0];

        dados.forEach((item: { id: number; nome: string; }) => {
          tiposAdministracao.push({ codigo: item.id, nome: item.nome, descricao: item.nome });
        });

        this.enumTipoAdministracao = tiposAdministracao;
      }

      this.form.patchValue(this.model);
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
      this.router.navigate(['/unidades-gestoras']);
    }
  }

  private desabilitarUgImportadoViaServico() {
    this.form.controls['cnpj'].disable();
    this.form.controls['codigoUg'].disable();
    this.form.controls['sigla'].disable();
    this.form.controls['gestao'].disable();
    this.form.controls['nome'].disable();
    this.form.controls['tipoAdministracaoId'].disable();
    this.enumTipoAdministracao = [{ codigo: this.model.tipoAdministracaoId, nome: this.model.tipoAdministracaoNome, descricao: this.model.tipoAdministracaoNome, guid: "" }];
  }

  public async salvarDados(salvarEFechar: boolean)
  {
    
    if (this.form.invalid) {
      this.exibirMensagemAlerta('Formulário inválido');
      return;
    }

    this.submit = true;

    this.atualizarModel(this.form.value);

    try {
      let res: IBaseModel<IUnidadeGestoraModel> = null;

      if (!this.novoRegistro) {
        res = await this.unidadeGestoraService.atualizar(this.model);
      } else {
        res = await this.unidadeGestoraService.inserir(this.model);
      }

      if (res.sucesso) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });

        if(salvarEFechar == true){
          this.router.navigate(['/unidades-gestoras']);
        }
        else{
          this.router.navigate([`/unidades-gestoras/${res.dados.id}`]);
        }

      } else {
        this.submit = false;
        const validacoes = this.montarMensagensValidacao(res);
        this.exibirMensagemAlerta(res.mensagem.descricao, true);
      }
    } catch (err) {
      this.submit = false;
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }


  private atualizarModel(values: any) {
    Object.assign(this.model, values);
  }

  public onBack() {
    this.submit = false;
    this.router.navigate(['/unidades-gestoras']);
  }

  public async exibirModalOrgao() {
    try {
      const res = await this.organogramaService.ObterOrgaos();
      if (res.sucesso) {
        const modal = this.exibirModal('Selecionar Órgão', res.dados, false);
        modal.afterClosed().subscribe(data => {
          if (data) {
            this.model.orgaoId = data.id;
            this.model.nomeOrgao = data.nome;
            this.form.controls['orgaoId'].setValue(data.id);
          }
        }
        );
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public async exibirModalResponsavelUnidade() {
    if (!this.model.orgaoId) {
      this.exibirMensagemAlerta('É necessário selecionar um órgão antes de escolher o responsável.');
      return;
    }

    try {
      const res = await this.acessoCidadaoService.ObterAgentesPublicosPorConjunto(this.model.orgaoId);
      if (res.sucesso) {
        const modal = this.exibirModal('Selecionar Responsável pela Unidade', res.dados, false);
        modal.afterClosed().subscribe(data => {
          if (data) {
            this.model.responsavelId = data.id;
            this.model.nomeResponsavel = data.nome;
            this.form.controls['responsavelId'].setValue(data.id);
          }
        }
        );
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }

  public async exibirModalUnidadeGestoraResponsavel() {
    try {
      const res = await this.unidadeGestoraService.obter();
      if (res.sucesso) {
        const customFilterPredicate = (data: { sigla: string, nome: string }, filterValue: string) => {
          return (data.sigla || '').trim().toLowerCase().indexOf(filterValue) !== -1
            || (data.nome || '').trim().toLowerCase().indexOf(filterValue) !== -1;
        };

        const modal = this.exibirModal('Selecionar Unidade Gestora Responsavel', res.dados.resultado.filter(_ => !this.ugDataSource.data.find(i => i.id === _.id)).map(c => ({ ...c, sigla: c.sigla })), false, [
          {
            columnDef: 'selecao',
            header: ''
          },
          {
            columnDef: 'sigla',
            header: 'Sigla'
          },
          {
            columnDef: 'nome',
            header: 'Nome'
          }], customFilterPredicate);
        modal.afterClosed().subscribe(data => {
          if (data) {
            this.model.unidadeGestoraResponsavelId = data.id;
            this.model.nomeUnidadeGestoraResponsavel = data.nome;
            this.form.controls['unidadeGestoraResponsavelId'].setValue(data.id);
          }
        }
        );
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: res.mensagem.descricao,
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });
      }
    } catch (err) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: err.mensagem.descricao,
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }


  public adicionarEnums(){
    this.enumAnosObrigatorios.push({ codigo: 2, nome: "1º", descricao: "1º" , guid: ""});
    this.enumAnosObrigatorios.push({ codigo: 4, nome: "2º", descricao: "2º" , guid: ""});
    this.enumAnosObrigatorios.push({ codigo: 8, nome: "3º", descricao: "3º" , guid: ""});
    this.enumAnosObrigatorios.push({ codigo: 16, nome: "4º", descricao: "4º" , guid: ""});
  }

  public async carregaMaturidades(){
    const filtro = {} as FiltroModel;
    this.unidadeGestoraMaturidadeGRService
    .obter(filtro)
    .then((res) => {
      this.maturidades = res.dados;
    })
    .catch((err) => {
      this.exibirMensagemAlerta(err.mensagem.descricao);
    });    
  }

  public async abrirModalHistoricoMaturidade(){
    const filtroHistorico = {} as IFiltroUnidadeGestoraMaturidadeGRHistoricoModel;
    filtroHistorico.idug = this.model.id;
    const dadosHistoricoMaturidade = await this.unidadeGestoraMaturidadeGRHistoricoService.obter(filtroHistorico)
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-historico-component';
    dialogConfig.width = '650px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    const txtTitulo = `Histórico da UG: ${this.model.sigla}`;
    dialogConfig.data = {
      historicoDados: dadosHistoricoMaturidade.dados,
      tituloModal: txtTitulo
    };

    const modal = this.matDialog.open(
      HistoricoModalComponent,
      dialogConfig
    );


  }

  onChangeAba(novaAba: number){
    //this.router.navigate([`/unidades-gestoras/${this.id}?aba=${novaAba}`]);
    this.router.navigateByUrl(`/unidades-gestoras/${this.id}?aba=${novaAba}`);
  }

  mudarAba(novaAba: number) {
    if (this.novoRegistro || (this.aba === 0 && this.salvarDadosPendente)) {
      this.exibirMensagemErro('Por favor, salve os dados antes de prosseguir');

      return;
    }

    this.aba = novaAba;
    this.router.navigateByUrl(`/unidades-gestoras/${this.id}?aba=${this.aba}`);
  }
  

}
