import { BaseFormComponent } from "src/app/shared/components/base-form/base-form.component";
import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { IModeloQuestionarioItemModel, IModeloQuestionarioModel } from "src/app/models/modelo-questionario.model";
import { ModeloQuestionarioService } from "src/app/services/modelo-questionario.service";
import { IPermissaoModel } from "src/app/models/permissao.model";
import { EnumRecursoPerfil } from "src/app/models/enum/recurso-perfil.enum";
import { EnumAcaoRecurso } from "src/app/models/enum/acao-recurso.enum";
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TabelaDominioService } from "src/app/services/tabela-dominio.service";
import { IEnumModel } from "src/app/models/enum.model";
import { PerguntaService } from "src/app/services/pergunta.service";
import { ModalModeloQuestionarioPerguntaComponent } from "./modal-pergunta/modal-pergunta.component";
import { ModalModeloQuestionarioSecaoComponent } from "./modal-secao/modal-secao.component";
import { MatDialogConfig } from "@angular/material/dialog";
import { ModalCancelarModeloQuestionario } from "./modal-cancelar/modal-cancelar.component";

@Component({
    selector: 'app-modelo-questionario-form',
    templateUrl: './modelo-questionario-form.component.html',
    styleUrls: ['./modelo-questionario-form.component.scss']
  })

export class ModeloQuestionarioFormComponent extends BaseFormComponent  implements OnInit{
    
    public model= {} as IModeloQuestionarioModel;
    public tiposDocumentos = [] as IEnumModel[];
    columnsToDisplay = ['ordem', 'nome', 'tipoDescription', 'obrigatorio', 'actions'];
    subColumnsToDisplay = ['ordem', 'nome', 'tipoDescription', 'obrigatorio', 'actions'];
    public permissoes: IPermissaoModel;

    constructor(
        private modeloQuestionarioService: ModeloQuestionarioService,
        toastr: ToastrService,
        route: ActivatedRoute,
        router: Router,
        private authService: AuthService,
        localeService: BsLocaleService,
        private perguntaService: PerguntaService,
        private tabelaDominioService: TabelaDominioService,
        public matDialog: MatDialog
      ) {
        super(route, toastr, router, localeService, matDialog);
      }


      public form = new FormGroup({
        id: new FormControl({value: '', disabled:true}),
        nome: new FormControl('', Validators.required),
        tipoDocumento: new FormControl(null, Validators.required)
      })

      async ngOnInit(){
        this.setarPermissoes();

        await this.tabelaDominioService
                    .obterTiposDocumentoTrabalho()
                    .then((res) => this.tiposDocumentos = res.dados)
                    .catch((err) => this.exibirMensagemErro(err));

        await this.buscar();          
      }

      async buscar(){
        if(!this.novoRegistro){
          this.modeloQuestionarioService
          .obterPorId(this.id)
          .then((res) => {
              if(res.sucesso){
                  this.model = res.dados
                  this.form.controls['nome'].setValue(res.dados.nome);
                  this.form.controls['id'].setValue(res.dados.id);
                  this.form.controls['tipoDocumento'].setValue(res.dados.tipoDocumentoId);
                  this.titulo = "Editar modelo questionário - " + this.model.nome;
                  this.reordenar();
              }else{
                  this.exibirMensagemErro(res.mensagem.descricao);
              }
          })
          .catch((err) => this.exibirMensagemErro(err));
      }else{
        this.titulo = "Novo modelo questionário";
      }
    }

      public async salvarDados(salvarEFechar: boolean){
        if(this.novoRegistro){
          if(!this.permissoes.incluir){
            this.exibirMensagemErro("Você não possui permissão para Incluir.");
            this.router.navigate(['/modelo-questionario']);
            return;
          }
        }else{
          if(!this.permissoes.editar){
            this.exibirMensagemErro("Você não possui permissão para Editar.");
            return;
          }
        }
        
        if (this.form.invalid) {
          this.exibirMensagemErro('Formulário invalido!');
          return;
        }

        this.model.nome = this.form.controls['nome'].value;
        this.model.id = this.id ?? 0;
        this.model.tipoDocumentoId = this.form.controls['tipoDocumento'].value;

          if(this.novoRegistro){
            this.modeloQuestionarioService
              .inserir(this.model)
              .then((res) => {
                if(res.sucesso){
                  this.exibirMensagemSucesso(res.mensagem.descricao);
                  if(salvarEFechar){
                    this.router.navigate(['/modelo-questionario']);
                  }else{
                    this.router.navigate([`/modelo-questionario/${res.dados.id}`]);
                    this.buscar();
                  }
                }else{
                  this.exibirMensagemErro(res.mensagem.descricao);
                }
              })
              .catch((err) => this.exibirMensagemErro(err));
          }else{
            this.modeloQuestionarioService
              .atualizar(this.model)
              .then((res) => {
                if(res.sucesso){
                  this.exibirMensagemSucesso(res.mensagem.descricao);
                  if(salvarEFechar){
                    this.router.navigate(['/modelo-questionario']);
                  }else{
                    this.buscar();
                  }
                }else{
                  this.exibirMensagemErro(res.mensagem.descricao);
                }
              })
              .catch((err) => this.exibirMensagemErro(err));
          }
        
      }

      public drop(event: CdkDragDrop<string[]>, element: any) {
        let contadorGeralIndice = 0;
        let contadorItem = 0;
        let contadorSubItem = 0;
        let indiceAnteriorModel = -1;
        let indiceAtualModel = -1;
        let moveu = false;

        this.model.itens.forEach((item, idx) => {
          if(moveu == true){
            return;
          }

          if(item.nome == element.nome){
            if(contadorGeralIndice == event.previousIndex){
              indiceAnteriorModel = idx;
            }
  
            if(contadorGeralIndice == event.currentIndex){
              indiceAtualModel = idx;
            }
  
            if(indiceAnteriorModel >= 0 && indiceAtualModel >= 0){
              moveItemInArray(this.model.itens, indiceAnteriorModel, indiceAtualModel); 
              moveu = true;       
              return;
            }
  
            contadorGeralIndice += 1;
  
            if(item.subItens){
              item.subItens.forEach((subItem, subIdx) => {
                debugger
                if(moveu == true){
                  return;
                }
                
                if(contadorGeralIndice == event.previousIndex){
                  indiceAnteriorModel = subIdx;
                }
  
                if(contadorGeralIndice == event.currentIndex){
                  indiceAtualModel = subIdx;
                }
  
                if(indiceAnteriorModel >= 0 && indiceAtualModel >= 0){
                  moveItemInArray(item.subItens, indiceAnteriorModel, indiceAtualModel); 
                  moveu = true;       
                  return;
                }
  
  
                contadorGeralIndice += 1;
              });
            }
          }
          
        });

        //moveItemInArray(this.model.itens, event.previousIndex, event.currentIndex);
        this.reordenar();
      }


      public reordenar(){
        this.model.itens.forEach((item, idx) => {
          item.ordem = (idx + 1);
          item.ordemExibicao = item.ordem.toString();
          if(item.subItens){
            item.subItens.forEach((subItem, subIdx) => {
              const itemPai = this.model.itens.find(_ => _.id == subItem.idItemPai);
              subItem.ordem = subIdx + 1;
              subItem.ordemExibicao = `${item.ordemExibicao}.${subItem.ordem.toString()}`
            });
          }
        });
      }


      public async exibirModalPergunta(idItemPai: number, idx: number){
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = 'modal-component';
        dialogConfig.width = '650px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        const modal = this.matDialog.open(ModalModeloQuestionarioPerguntaComponent, dialogConfig);
        modal.afterClosed().subscribe((data) => {
          if(data){
            let novoItem = {} as IModeloQuestionarioItemModel;
            novoItem.idModeloQuestionario = this.id;
            novoItem.ativo = true;
            novoItem.nome = data.nome;
            novoItem.tipoId = 2;
            novoItem.tipoDescription = data.tipoEntrada.descricao;
            novoItem.obrigatorio = data.obrigatorio;
            novoItem.perguntaId = data.id;

            if(idItemPai == -1 && idx == -1){
              if(!this.model.itens)
                this.model.itens = [] as IModeloQuestionarioItemModel[];

              this.model.itens.push(novoItem);
            }else{
              const itemPai = this.model.itens[idx];
              
              if(!itemPai.subItens)
                itemPai.subItens = [] as IModeloQuestionarioItemModel[];

              itemPai.subItens.push(novoItem);
            }
            this.reordenar();
          }
        })
      }


      public exibirModalSecao(element: any){
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = 'modal-component';
        dialogConfig.width = '650px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          id: element ? element.id : 0,
          idModeloQuestionario : this.id,
          nome: element ? element.nome : ""
        };

        const modal = this.matDialog.open(ModalModeloQuestionarioSecaoComponent, dialogConfig);
        modal.afterClosed().subscribe((data) => {
          if(data){

            let novaSecao = data as IModeloQuestionarioItemModel;

            if(!this.model.itens || this.model.itens.length == 0){
              this.model.itens = [] as IModeloQuestionarioItemModel[];
              this.model.itens.push(novaSecao);
              this.reordenar();
              return;
            }

            if(this.model.itens.find((element)=> element.nome.toUpperCase() == novaSecao.nome.toUpperCase())){
              this.exibirMensagemErro("Já existe seção com este nome.");
              return;
            }          

            var isNew = true;
            
            this.model.itens.forEach((element, idx) => {
              if(novaSecao.nomeAnterior == element.nome 
                    && novaSecao.nomeAnterior != ""){
                element.nome = novaSecao.nome;
                isNew = false;
              }
            });

            if(isNew){
              this.model.itens.push(novaSecao);
            }
            
            this.reordenar();
          }
        })
      }

      public excluirItem(elementPai: IModeloQuestionarioItemModel, element: IModeloQuestionarioItemModel){
        
        
        if(!elementPai){
          var idxOf = this.model.itens.indexOf(element, 0);
          this.model.itens.splice(idxOf, 1);
        }else{
          const idxIndicePai = this.model.itens.indexOf(elementPai, 0);
          const itemPai = this.model.itens[idxIndicePai];
          var idxOf = itemPai.subItens.indexOf(element,0);
          itemPai.subItens.splice(idxOf, 1);
        }
        
        this.reordenar();
      }

      public onBack() {
        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.id = 'modal-component';
        dialogConfig.width = '650px';
        dialogConfig.hasBackdrop = true;
        dialogConfig.disableClose = true;

        const modal = this.matDialog.open(ModalCancelarModeloQuestionario, dialogConfig);
        modal.afterClosed().subscribe((data) => {
          if(data == true){
            this.router.navigate(['/modelo-questionario']);  
          }
        })
      }

      public semDados(): boolean{
        return !this.model || !this.model.itens || this.model.itens.length == 0;
      }

      private setarPermissoes(): void {
        this.permissoes = new IPermissaoModel();
        this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.ModeloQuestionario, EnumAcaoRecurso.Consultar);
        this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.ModeloQuestionario, EnumAcaoRecurso.Editar);
        this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.ModeloQuestionario, EnumAcaoRecurso.Excluir);
        this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.ModeloQuestionario, EnumAcaoRecurso.AtivarInativar);
        this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.ModeloQuestionario, EnumAcaoRecurso.Inserir);
        this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.ModeloQuestionario, EnumAcaoRecurso.Visualizar);


        if(this.novoRegistro){
          if(!this.permissoes.incluir){
            this.exibirMensagemErro("Você não possui permissão para Incluir.");
            this.router.navigate(['/modelo-questionario']);
            return;
          }
        }else{
          if(!this.permissoes.visualizar){
            this.exibirMensagemErro("Você não possui permissão para Visualizar.");
            this.router.navigate(['/modelo-questionario']);
            return;
          }
        }
     }
}