import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { FiltroModel } from 'src/app/models/filtro.model';
import { QuestionarioUGService } from 'src/app/services/questionarioug.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/shared.service';
import { IArquivoModel } from 'src/app/models/arquivo.model';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { EnumTipoQuestionarioUG } from 'src/app/models/enum/tipo-questionario-ug.enum';

@Component({
  selector: 'app-importacao-arquivo-questionario-shared',
  templateUrl: './importacao-arquivo-questionario.component.html',
  styleUrls: ['./importacao-arquivo-questionario.component.scss']
})
export class ImportacaoArquivoQuestionarioSharedComponent extends BaseFormComponent implements OnInit  {
  public filtro = {} as FiltroModel;
  model: any;
  unidadeGestoraId: number;
  modeloRelatorioId: number;
  arquivo: File | null = null;
  mensagem: string = '';
  arquivoUrl: string;
  nomeArquivo: string;
  titulo: string = '';
  tipoQuestionario: number;

  constructor(
    private authService: AuthService,
    private questionarioUGService: QuestionarioUGService,
    matDialog: MatDialog,
    router: Router,
    private sharedService: SharedService,
    route: ActivatedRoute,
    toastr: ToastrService,
    localeService: BsLocaleService
  ) { 
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {
    this.unidadeGestoraId = this.sharedService.getId();
    this.tipoQuestionario = this.sharedService.getTipoQuestionario();

    if(this.tipoQuestionario == EnumTipoQuestionarioUG.EntendendoEntidade){
      this.titulo = "Entendendo a Entidade";
    }else if (this.tipoQuestionario == EnumTipoQuestionarioUG.MaturidadeRisco){
      this.titulo = "Maturidade de Risco";
    }
    this.downloadAnexo();
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      // Verifica se a extensão do arquivo é PDF
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'pdf') {
        // Emite um aviso caso a extensão não seja PDF
        this.toastr.error('O tipo de arquivo selecionado não é válido, selecione apenas arquivo PDF!', 'Extensão inválida');
        this.arquivo = null; // Reseta o arquivo selecionado
      } else {
        this.arquivo = file;
      }
    }
  }

  downloadAnexo(): void {

    this.questionarioUGService.DownloadArquivo(this.unidadeGestoraId).then(
      (res) => {
        this.arquivoUrl = res.dados.url;
        this.nomeArquivo = res.dados.nomeArquivo;
   },
      (error) => {
      }
    );

  }

  enviarArquivo(): void {
    if (this.arquivo) {
      const tipoQuestionarioId = this.sharedService.getTipoQuestionario();
      const arquivoModel: IArquivoModel = {
        id: 0,
        nomeArquivo: this.arquivo.name,
        entidadeArquivo: tipoQuestionarioId, 
        arquivo: this.arquivo,
        url: ''
      };

      

      this.questionarioUGService.enviarArquivo(this.unidadeGestoraId, tipoQuestionarioId ,arquivoModel).then(
        (response) => {
          this.downloadAnexo();

          let aba = 0;
          if(this.tipoQuestionario == 1){
            aba = 1;
          }else if(this.tipoQuestionario == 2){
            aba = 2;
          }

          this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=1`);
          window.location.reload();
        },
        (error) => {
          this.exibirMensagemErro(error.mensagem.descricao);
        }
      );
    }
  }

  public onBack() {
    this.submit = false;

    let aba = 0;
    if(this.tipoQuestionario == 1){
      aba = 1;
    }else if(this.tipoQuestionario == 2){
      aba = 2;
    }
    
    this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=1`);
  }


}
