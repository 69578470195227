<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i class="fas fa-table pr-2"></i>
              {{titulo}}
            </h3>
          </div>

          <div class="card-body p-2">
            <div class="mat-elevation-z4">
              <mat-toolbar class="table-header">
                <mat-toolbar-row>
                  <div class="form-group d-flex align-items-center mb-0 w-100">
                    <label for="fileInput" class="mr-2 font-weight-bold">Arquivo:</label>
                    <input type="text" [value]="arquivo?.name || ''" readonly class="form-control nome-arquivo mr-2 flex-grow-1"/>
                    <input type="file" id="fileInput" (change)="onFileSelected($event)" class="form-control-file selecionar-arquivo mr-2"/>
                    <button type="button" class="btn btn-success btn-sm" (click)="enviarArquivo()">
                      <i class="far fa-file"></i> Enviar
                    </button>
                  </div>
                    <div class="form-group d-flex align-items-center mb-0 w-100">
                    <a [href]="arquivoUrl" download="{{ nomeArquivo }}" class="link-download">
                      <i class="fa fa-paperclip"></i> {{ nomeArquivo }}
                    </a>
                    </div>
                  


                </mat-toolbar-row>
              </mat-toolbar>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button [hidden]="visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
    <i class="fas fa-times"></i> Fechar
  </button> 
</div>
