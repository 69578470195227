import { ToastrService } from 'ngx-toastr';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { IBaseModel } from 'src/app/models/base.model';
import { MatTableDataSource } from '@angular/material/table';
import { IModeloQuestionarioModel } from 'src/app/models/modelo-questionario.model';
import { ModeloQuestionarioService } from 'src/app/services/modelo-questionario.service';
import { FiltroModel } from 'src/app/models/filtro.model';
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';

@Component({
  selector: 'app-modal-selecao-questionario',
  templateUrl: './modal-selecao-questionario.component.html',
  styleUrls: ['./modal-selecao-questionario.component.scss']
})
export class ModalSelecaoQuestionarioComponent extends BaseFormComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Output() nomeSelecionado = new EventEmitter<string>();
  
  public filtro = {} as FiltroModel;
  public dataSource = new MatTableDataSource<IModeloQuestionarioModel>([]);
  public semDados = true;
  public displayedColumns: string[] = ['selecao', 'nome'];
  public selecionado: IModeloQuestionarioModel;
  public totalItens: number;
 
  constructor(
    route: ActivatedRoute,
    private dialogRef: MatDialogRef<ModalSelecaoQuestionarioComponent>,
    public toastr: ToastrService,
    localeService: BsLocaleService,
    router: Router,
    private modeloQuestionarioService: ModeloQuestionarioService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data

  ) { 
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {
    this.filtro.ativo = true;
    this.buscarModeloQuestionario();
  }
 
  
  ngAfterViewInit(): void {
    
  }


  public selecionar(item: IModeloQuestionarioModel) {
    this.selecionado = !this.selecionado || this.selecionado.id !== item.id ? item : null;
    this.nomeSelecionado.emit(item.nome);
  }

  public async concluir() {
    this.dialogRef.close(this.selecionado);
  }

  public buscarModeloQuestionario(){
    this.modeloQuestionarioService
            .obterModeloEntendendoEntidade(this.filtro)
            .then((res) => {
               if(res.sucesso){
                this.totalItens = res.dados.totalItens;
                this.dataSource = new MatTableDataSource<IModeloQuestionarioModel>(res.dados.resultado);
                this.dataSource.sort = this.sort;
                this.dataSource.sortingDataAccessor = defaultDataAcessor;
                this.dataSource._updateChangeSubscription();
                this.semDados = this.dataSource.filteredData.length == 0;
                }
            });
    
  }

  public filtrar(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.semDados = this.dataSource.filteredData.length === 0;
  }

  public cancelar() {
    this.dialogRef.close(null);
  }

  

}
