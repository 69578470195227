import { Component, NgModule, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IEnumModel } from 'src/app/models/enum.model';
import { IListaQuestionarioRespostaUGModel, IQuestionarioUGModel, IVisualizarQuestionarioRespostaUGModel, QuestionarioUGItensResposta } from 'src/app/models/questionario-ug.model';
import { AuthService } from 'src/app/services/auth.service';
import { QuestionarioUGService } from 'src/app/services/questionarioug.service';
import { PerguntaService } from 'src/app/services/pergunta.service';
import { TabelaDominioService } from 'src/app/services/tabela-dominio.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableDataSource } from '@angular/material/table';
import { QuestionarioUGRespostaService } from 'src/app/services/questionarioug-resposta.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-visualizar-questionario',
  templateUrl: './visualizar-questionario.component.html',
  styleUrls: ['./visualizar-questionario.component.scss']
})

export class VisualizarQuestionarioComponent extends BaseFormComponent implements OnInit {
  public model= {} as IListaQuestionarioRespostaUGModel;
  public tiles = [];
  public idUnidadeGestora : number;
  public semDados: boolean;
  public dataSource = new MatTableDataSource<QuestionarioUGItensResposta>([]);
  displayedColumns: string[] = ['pergunta','tipoAvaliacaoAuditor', 'avaliacaoAuditor', 'tipoReplica', 'replica', 'avaliacaoFinal', 'tipoAvaliacaoFinal'];
  public titulo: string = 'Visualizar questionário';

  constructor(
    private questionarioUGService: QuestionarioUGService,
    toastr: ToastrService,
    route: ActivatedRoute,
    router: Router,
    private authService: AuthService,
    localeService: BsLocaleService,
    public sharedService: SharedService,
    private perguntaService: PerguntaService,
    private tabelaDominioService: TabelaDominioService,
    private questionarioService: QuestionarioUGRespostaService,
    public matDialog: MatDialog,
    public matGridListModule: MatGridListModule
  ) { 
    super(route, toastr, router, localeService, matDialog);
  }

  public form = new FormGroup({
    id: new FormControl({value: '', disabled:true}),
    nome: new FormControl('', Validators.required),
    tipoDocumento: new FormControl({value:[] as IEnumModel[]})
  })

  async ngOnInit() {

    if(!this.novoRegistro){
      this.questionarioService.ObterRespostaQuestionario(this.id)
      .then((res) => {
        this.model = res.dados;
        this.dataSource = new MatTableDataSource<QuestionarioUGItensResposta>(res.dados.respostas);
      });
    }  

  }

  public onBack() {
    this.submit = false;
    let aba = this.sharedService.getTipoQuestionario();
    this.router.navigateByUrl(`/unidades-gestoras/${this.idUnidadeGestora}?aba=${aba}`);
  }

}
