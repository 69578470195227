import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FiltroModel } from 'src/app/models/filtro.model';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';
import { QuestionarioUGService } from 'src/app/services/questionarioug.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { ModalSelecaoQuestionarioSharedComponent } from '../modal-selecao-questionario/modal-selecao-questionario.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/shared.service';
import { EnumTipoQuestionarioUG } from 'src/app/models/enum/tipo-questionario-ug.enum';

@Component({
  selector: 'app-gerar-questionario-shared',
  templateUrl: './gerar-questionario.component.html',
  styleUrls: ['./gerar-questionario.component.scss']
})
export class GerarQuestionarioSharedComponent extends BaseListComponent {
  public filtro = {} as FiltroModel;
  model: any;
  unidadeGestoraId: number;
  modeloRelatorioId: number;
  tipoQuestionario: number;
  titulo: string;
  
  
  constructor(
    private authService: AuthService,
    private questionarioUGService: QuestionarioUGService,
    private matDialog: MatDialog,
    private router: Router,
    private sharedService: SharedService
  ) { 
    super();
  }

  ngOnInit(): void {
    this.unidadeGestoraId = this.sharedService.getId();
    this.tipoQuestionario = this.sharedService.getTipoQuestionario();

    if(this.tipoQuestionario == EnumTipoQuestionarioUG.EntendendoEntidade){
      this.titulo = "Entendendo a Entidade";
    }else if (this.tipoQuestionario == EnumTipoQuestionarioUG.MaturidadeRisco){
      this.titulo = "Maturidade de Risco";
    }
  }

  public gerarQuestionario() {
    this.questionarioUGService
    .inserir(this.modeloRelatorioId,this.unidadeGestoraId)
    .then((res) => {
      if (res.sucesso) {
        // aqui chamar o pagina principal
        let aba = 0;
        if(this.tipoQuestionario == EnumTipoQuestionarioUG.EntendendoEntidade){
          aba = 1;
        }else if (this.tipoQuestionario == EnumTipoQuestionarioUG.MaturidadeRisco){
          aba = 2;
        }

        this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=${aba}`);
      } else {
        this.exibirMensagemAlerta(res.mensagem.descricao);
      }
    })
    .catch((err) => {
      this.exibirMensagemErro(err.mensagem);
    })
    .finally(() => {
      // this.buscar();
    });
  }

  public buscarQuestionario(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'modal-component';
    dialogConfig.width = '650px';
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;

    const modal = this.matDialog.open(ModalSelecaoQuestionarioSharedComponent, dialogConfig);
    modal.afterClosed().subscribe((data) => {
      if(data){
        this.filtro.pesquisa = data.nome;
        this.modeloRelatorioId = data.id;
      }
    })
  }

  public exibirModalquestionario(){
    
  }

}
