import { BaseListComponent } from "src/app/shared/components/base-list/base-list.component";
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { IModeloQuestionarioModel } from "src/app/models/modelo-questionario.model";
import { ModeloQuestionarioService } from "src/app/services/modelo-questionario.service";
import { IPermissaoModel } from "src/app/models/permissao.model";
import { EnumRecursoPerfil } from "src/app/models/enum/recurso-perfil.enum";
import { EnumAcaoRecurso } from "src/app/models/enum/acao-recurso.enum";
import { FiltroModel } from "src/app/models/filtro.model";
import { defaultDataAcessor } from 'src/app/core/helpers/mat-sort-data-accessor';




@Component({
    selector: 'app-modelo-questionario-list',
    templateUrl: './modelo-questionario-list.component.html',
    styleUrls: ['./modelo-questionario-list.component.scss']
  })
  export class ModeloQuestionarioListComponent extends BaseListComponent  {
    
      public filtro = {} as FiltroModel;
      public dataSource = new MatTableDataSource<IModeloQuestionarioModel>([]);
      public totalItens: number;
      public searchNome: string;
      public searchAtivo = true;
      public semDados = true;
      public selectedModel: IModeloQuestionarioModel;
      public pageEvent: any;
      

      constructor(
        private modeloQuestionarioService: ModeloQuestionarioService,
        private toastr: ToastrService,
        private router: Router,
        private authService: AuthService
      ) {
        super();
      }

      @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
      @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
      @ViewChild(MatSort, { static: true }) sort: MatSort;

      public displayedColumns: string[] = ['selecao', 'nome', 'tipoDocumentoDescription',  'ativo'];

      ngOnInit(): void {
        this.setarPermissoes();
        this.configurarFiltro();
        this.buscar();
      }

      public novo() {
        if(!this.permissoes.incluir){
          this.exibirMensagemErro("Você não possui permissão para Incluir.")
          return
        }

        this.router.navigate(['/modelo-questionario/novo']);
      }

      public editar(id: number){
        if(!this.permissoes.editar){
          this.exibirMensagemErro("Você não possui permissão para Editar.")
          return
        }

        this.router.navigate([`/modelo-questionario/${id}`]);
      }

      public inativar(id: number){
        if(!this.permissoes.inativarReativar){
          this.exibirMensagemErro("Você não possui permissão para Inativar-Reativar.")
          return
        }

        this.modeloQuestionarioService
            .inativar(id)        
            .then((res) => {
                if(res.sucesso){
                    this.selectedModel = null;
                    this.exibirMensagemSucesso(res.mensagem.descricao);
                    this.resetarFiltro();
                    this.buscar();
                }else{
                    this.exibirMensagemErro(res.mensagem.descricao);
                }
            })
            .catch((err)=> this.exibirMensagemErro(err));
      }

      public reativar(id: number){
        if(!this.permissoes.inativarReativar){
          this.exibirMensagemErro("Você não possui permissão para Inativar-Reativar.")
          return
        }

        this.modeloQuestionarioService
            .reativar(id)        
            .then((res) => {
                if(res.sucesso){
                    this.selectedModel = null;
                    this.exibirMensagemSucesso(res.mensagem.descricao);
                    this.resetarFiltro();
                    this.buscar();
                }else{
                    this.exibirMensagemErro(res.mensagem.descricao);
                }
            })
            .catch((err)=> this.exibirMensagemErro(err));
      }

      public excluir(id: number){
        if(!this.permissoes.excluir){
          this.exibirMensagemErro("Você não possui permissão para Excluir.")
          return
        }

        this.modeloQuestionarioService
            .excluir(id)        
            .then((res) => {
                if(res.sucesso){
                    this.exibirMensagemSucesso(res.mensagem.descricao);
                    this.buscar()
                }else{
                    this.exibirMensagemErro(res.mensagem.descricao);
                }
            })
            .catch((err)=> this.exibirMensagemErro(err));
      }

      public visualizar(id: number){
        if(!this.permissoes.visualizar){
          this.exibirMensagemErro("Você não possui permissão para visualizar.")
          return
        }

        this.router.navigate([`/modelo-questionario/${id}/visualizar`]);
      }

      public selecionar(item: any) {
        this.selectedModel = !this.selectedModel || this.selectedModel.id !== item.id ? item : null;
      }

      pageChanged(e) {
        this.filtro.pagina = e.pageIndex + 1;
        e.pageIndex = this.filtro.pagina;
        this.filtro.itensPorPagina = e.pageSize;
        this.buscar();
      }
    
      private setarPermissoes(): void {
        this.permissoes = new IPermissaoModel();
        this.permissoes.consultar = this.authService.possuiPermissao(EnumRecursoPerfil.ModeloQuestionario, EnumAcaoRecurso.Consultar);
        this.permissoes.editar = this.authService.possuiPermissao(EnumRecursoPerfil.ModeloQuestionario, EnumAcaoRecurso.Editar);
        this.permissoes.excluir = this.authService.possuiPermissao(EnumRecursoPerfil.ModeloQuestionario, EnumAcaoRecurso.Excluir);
        this.permissoes.inativarReativar = this.authService.possuiPermissao(EnumRecursoPerfil.ModeloQuestionario, EnumAcaoRecurso.AtivarInativar);
        this.permissoes.incluir = this.authService.possuiPermissao(EnumRecursoPerfil.ModeloQuestionario, EnumAcaoRecurso.Inserir);
        this.permissoes.visualizar = this.authService.possuiPermissao(EnumRecursoPerfil.ModeloQuestionario, EnumAcaoRecurso.Visualizar);
     }

     public async buscar(){
      if(!this.permissoes.consultar){
        this.exibirMensagemErro("Você não possui permissão para consultar.")
        return
      }

        this.configurarFiltro();
        this.modeloQuestionarioService
            .obter(this.filtro)
            .then((res) => {
                if(res.sucesso){
                    this.totalItens = res.dados.totalItens;
                    this.dataSource = new MatTableDataSource<IModeloQuestionarioModel>(res.dados.resultado);
                    this.dataSource.sort = this.sort;
                    this.dataSource.sortingDataAccessor = defaultDataAcessor;
                    this.dataSource._updateChangeSubscription();
                    this.semDados = this.dataSource.filteredData.length == 0;
                }else{
                    this.exibirMensagemErro(res.mensagem.descricao);
                }
            })
            .catch((err) => this.exibirMensagemErro(err));
     }

     private configurarFiltro() {
        this.filtro.pagina = this.tableOnePaginator.pageIndex + 1;
        this.filtro.itensPorPagina = this.tableOnePaginator.pageSize;
        this.filtro.ativo = this.searchAtivo;
        this.filtro.pesquisa = this.searchNome;
        this.filtro.direcao = this.sort.direction;
        this.filtro.colunaOrder = this.sort.active;
      }

      private resetarFiltro(){
        this.filtro.pagina = 1;
        this.tableOnePaginator.pageIndex = 0;
        this.filtro.ativo = true;
      }
  }