<div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item">
              <a [routerLink]="['/']">
                <i class="fas fa-home"></i>
                Página Inicial</a> 
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/modelo-questionario']">Modelo de Questionário</a>
            </li>
            <li class="breadcrumb-item active">{{ titulo }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-outline card-lightblue">
            <div class="card-header">
              <h3 class="card-title mb-0">
                <i *ngIf="this.id" class="fas fa-pencil-alt pr-2"></i>
                <i *ngIf="!this.id" class="far fa-file pr-2"></i>
                {{ titulo }}
              </h3>
            </div>
  
            <form [formGroup]="form" #f="ngForm"  (keydown.enter)="$event.preventDefault()">
              <div class="card-body">
  
                <div class="row">
                  <div class="form-group col-md-5">
                    <label for="nome" class="mb-1">Nome</label>
                    <input [attr.disabled]="visualizar ? true : null" type="text" id="nome" 
                      class="form-control form-control-sm" formControlName="nome"
                      maxlength="100" [ngClass]="{ 'is-invalid': f.submitted && form.controls['nome'].invalid }" />
                    <div *ngIf="f.submitted && form.controls['nome'].invalid" class="invalid-feedback">
                      <div *ngIf="form.controls['nome'].errors.required">Campo obrigatório</div>
                    </div>
                  </div>
                  <div class="col-md-4 form-group">
                    <label
                        for="tipoDocumento"
                        class="mb-1"
                        >Documento de Trabalho</label
                    >
                    <select
                        formControlName="tipoDocumento"
                        class="form-control form-control-sm"
                        [ngClass]="{ 'is-invalid': f.submitted && form.controls['tipoDocumento'].invalid }"
                        [attr.disabled]="visualizar ? true : null"
                    >
                        <option
                            *ngFor="
                                let element of tiposDocumentos
                            "
                            [value]="element.codigo"
                        >
                            {{ element.codigo }} -
                            {{ element.descricao }}
                        </option>
                    </select>
                    <div *ngIf="f.submitted && form.controls['tipoDocumento'].invalid" class="invalid-feedback">
                        <div *ngIf="form.controls['tipoDocumento'].errors.required">Campo obrigatório</div>
                      </div>
                    </div>
                </div>
  
                <div class="row">
                    <div class="card card-outline card-lightblue col-md-12">
                        <div class="card-header">
                            <h3 class="card-title mb-0">
                              <i class="fas fa-table pr-2"></i>
                              Perguntas
                            </h3>
                          </div>

                        <div class="card-body p-2">
                            <div class="mat-elevation-z4">
                                <mat-toolbar class="table-header">
                                    <mat-toolbar-row>
                                      <div class="btn-group" [hidden]="visualizar">
                                          <button class="btn btn-primary btn-sm mr">
                                              <i></i> Adicionar
                                          </button>
                                          <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                                          <div class="dropdown-menu" role="menu">
                                          <a class="dropdown-item" (click)="exibirModalPergunta(-1,-1)">Pergunta</a>
                                          <a class="dropdown-item" (click)="exibirModalSecao(null)">Seção</a>
                                          </div>
                                      </div>
                                    </mat-toolbar-row>
                                  </mat-toolbar>
                                <table class="table table-condensed">
                                    <thead>
                                        <tr>
                                            <th class="col-md-1">#</th>
                                            <th class="col-md-6">Nome</th>
                                            <th class="col-md-2">Tipo</th>
                                            <th class="col-md-1 text-center">Obrigatório</th>
                                            <th class="col-md-1 text-center">Status</th>
                                            <th class="col-md-3 text-center">Ações</th>
                                        </tr>
                                    </thead>
    
                                    <tbody
                                    cdkDropList
                                    (cdkDropListDropped)="drop($event, element)"
                                    *ngFor="let element of model.itens; let i = index">

                                        <tr [attr.disabled]="visualizar"
                                            cdkDrag
                                            cdkDragLockAxis="y">
                                                <th>
                                                    <div class="drag-handle">
                                                        <ng-container
                                                        [ngTemplateOutlet]="dragHandleTmpl"
                                                        >
                                                        </ng-container>
                                                        {{ element?.ordemExibicao }}
                                                    </div>
                                                </th>
                                                <td >
                                                  <span class="mat-column" [ngClass]="{ bold: element.tipoId === 1 }">{{ element?.nome }}</span>
                                                </td>
                                                <td>{{ element?.tipoDescription }}</td>
                                                <td [hidden]="!element?.obrigatorio" class="text-center"> Sim </td>
                                                <td [hidden]="element?.obrigatorio"> </td>

                                                <td class="text-center">
                                                  <span *ngIf="element.ativo == null || element.ativo == undefined" class="badge bg-success text-xs"
                                                    ></span
                                                  >
                                                  <span *ngIf="element.ativo === true" class="badge bg-success text-xs"
                                                    >Ativo</span
                                                  >
                                                  <span
                                                    *ngIf="element.ativo === false"
                                                    class="badge bg-gray-dark text-xs"
                                                    >Inativo</span
                                                  >
                                                </td>

                                                <td class="text-center">
                                                    <div class="btn-group mr-1">
                                                      <button
                                                        [hidden]="visualizar || element.tipoId == 2"
                                                        class="btn btn-primary btn-xs text-left"
                                                        (click)="exibirModalSecao(element)"
                                                        >
                                                        <i class="fas fa-pen"></i>
                                                        </button>  
                                                      <button
                                                        [hidden]="visualizar || element.tipoId == 2"
                                                        class="btn btn-success btn-xs text-left"
                                                        (click)="exibirModalPergunta(element.id, i)"
                                                        >
                                                        <i class="fas fa-plus"></i>
                                                        </button>
                                                        <button
                                                        [hidden]="visualizar"
                                                        type="button"
                                                        class="btn btn-danger btn-xs text-left"
                                                        (click)="excluirItem(null, element)"
                                                        >
                                                        <i class="far fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>    
                                            
    
                                            
                                        <tr *ngFor="let subItem of element.subItens"
                                            cdkDrag
                                            cdkDragLockAxis="y">
                                            <th style="padding:25px">
                                                <div class="drag-handle">
                                                    <ng-container
                                                    [ngTemplateOutlet]="dragHandleTmpl"
                                                    >
                                                    </ng-container>
                                                    {{ subItem?.ordemExibicao }}
                                                </div>
                                            </th>
                                            <td style="padding:25px">{{ subItem?.nome }}</td>
                                            <td>{{ subItem?.tipoDescription }}</td>
                                            <td [hidden]="!subItem?.obrigatorio" class="text-center"> Sim </td>
                                            <td [hidden]="subItem?.obrigatorio">  </td>
                                            
                                            <td class="text-center">
                                              <span *ngIf="subItem.ativo" class="badge bg-success text-xs"
                                                >Ativo</span
                                              >
                                              <span
                                                *ngIf="!subItem.ativo"
                                                class="badge bg-gray-dark text-xs"
                                                >Inativo</span
                                              >
                                            </td>
                                            

                                            <td class="text-center">
                                                <div class="btn-group mr-1">
                                                    <button
                                                        [hidden]="visualizar"
                                                        type="button"
                                                        class="btn btn-danger btn-xs text-left"
                                                        (click)="excluirItem(element,subItem)">
                                                        <i class="far fa-trash-alt"></i>
                                                        </button>
                                                </div>
                                            </td>
                                        </tr>
                                            
                                    </tbody>
    
                                </table>
    
                                <ng-template #dragHandleTmpl>
                                    <svg
                                      class="bi bi-list handle"
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 16 16"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                  </ng-template>
    
                                <h5
                                    [hidden]="semDados"
                                    class="table-no-data"
                                >
                                    Nenhum item cadastrado
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                
  
              
  
              </div>
  
              <div class="card-footer">
                <div class="btn-group" [hidden]="visualizar">
                  <button class="btn btn-primary btn-sm mr" (click)="salvarDados(false)">
                      <i class="far fa-save"></i> Salvar
                  </button>
                  <button type="button" class="btn btn-primary btn-sm mr dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false"></button>
                  <div class="dropdown-menu" role="menu">
                  <a class="dropdown-item" (click)="salvarDados(false)">Salvar</a>
                  <a class="dropdown-item" (click)="salvarDados(true)">Salvar e fechar</a>
                  </div>
                </div>
  
                <button [hidden]="visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
                  <i class="fas fa-times"></i> Cancelar
                </button>
  
                <button [hidden]="!visualizar" type="button" class="btn btn-default btn-sm" (click)="onBack()">
                  <i class="fas fa-times"></i> Sair 
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  